import React, { useRef, useEffect, useState } from 'react';
import IMask from 'imask';
import { TextInput } from '@carbon/react'
import { inputMasks } from '../utils/inputMasks'

const MaskedTextInput = ({ maskType, value, ...rest }) => {
  const inputRef = useRef(null)
  const mask = inputMasks?.[maskType]

  useEffect(() => {
    if (inputRef.current) {
      const maskInstance = IMask(inputRef.current, mask)
      return () => maskInstance.destroy()
    }
  }, [maskType])

  return <TextInput type="text" value={value} {...rest} ref={inputRef} />
}

export default MaskedTextInput