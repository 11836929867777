import { useEffect, useState } from "react";
import { 
    Grid, 
    Column, 
    Select,
    SelectItem,
    Section,
} from '@carbon/react'

// api imports
import { getEntitiesHierarchyApi } from '../utils/api/entities'

const LEVEL_NUMBER = 12

const EntityNavigation = ({ selectedEntity, onChange=null }) => {
    const [ data, setData ] = useState([])
    const [ entityName, setEntityName ] = useState('')

    const getNavPath = (navData, entity) => {
        if (entity === null)
            return 'Todos'
        return navData.reduce((acc, el, index, array) => {
            const separator = (index === array.length - 1) || (el.id === entity) ? '' : ' > '
            return `${acc}${el.name}${separator}`
          }, '')
    }
    
    // query data as soon as we load
    useEffect(() => {
        async function fetchData() {
            const response = await getEntitiesHierarchyApi(selectedEntity)
            let treeData = []
            let name = null
            if (response?.hasOwnProperty('ancestors')) {
                treeData = [...response.ancestors.map(e => ({ id: e.id, name: e.name, level_entities: e.level_entities }))]
            }
            if (response?.hasOwnProperty('entity')) {
                name = response.entity.name
                treeData = [ ...treeData, { id: response.entity.id, name: response.entity.name, level_entities: response.entity.level_entities }]
            }
            if (response?.descendants?.length > 0)
                //setDescendants(response.descendants?.map(e => ({ id: e.id, name: e.name })))
                treeData = [ ...treeData, { id: "", name: "", level_entities: response.descendants.map(e => ({ id: e.id, name: e.name })) }]

            // in case null filter first level only
            if (response?.ancestors && !response?.entity && !response?.descendants) {
                treeData = [{
                    id: null,
                    name: '',
                    level_entities: [...treeData]
                }]
            }
            
            setData(treeData)
            setEntityName(name ?? '')

            // set parent root as select in case no selected
            //const entity = selectedEntity ? selectedEntity : treeData.length > 0 ? treeData[0].id : null
            onChange?.(selectedEntity, getNavPath(treeData, selectedEntity), name)
        }
        fetchData()
    }, [selectedEntity])

    const handleChange = (e) => {
        e.preventDefault()
        const value = e.target.value   
        if (value !== "")
            onChange?.(value, getNavPath(data, selectedEntity), entityName)
        else
            onChange?.(null, '', '')
    }
    
    return (
        <Section style={{ paddingInline: 0 }}>
            <Grid style={{ marginBottom: '32px', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr', gap: '15px 10px', alignItems: 'end', paddingInline: '0px' }} fullWidth>
                {data.map((e, idx) => (
                    <Column key={idx} style={{ marginInline: '0px' }}>
                        <Select
                            id={`${idx}`}
                            labelText={idx === 0 ? 'Ente Principal' : `Entidade Nível: ${idx}`}
                            onChange={handleChange}
                            value={`${e.id}`}
                        >
                            <SelectItem key={`${idx}-back`} value={data?.[idx-1]?.id} text={idx === 0 ? "Todos" : "Selecione"} />
                            {e.level_entities?.map((le, idx) => (
                                <SelectItem key={le.id} value={le.id} text={le.name} />
                            ))}
                        </Select>
                    </Column>
                ))}
{/*                 {Array.from({ length: LEVEL_NUMBER-data.length }).map((_, idx) => (
                    <Column key={`${idx+data.length}`} style={{ marginInline: '0px' }}>
                        <Select
                            id={`${idx+data.length}`}
                            disabled={true} 
                            labelText={`Entidade Nível: ${idx+data.length}`} 
                            onChange={handleChange}
                            defaultValue={""}
                        >
                            <SelectItem value="" text="Selecione" />
                        </Select>
                    </Column>
                ))} */}
            </Grid>
        </Section>
    )
}

export default EntityNavigation