


export const downloadExport = (dataExport, fileName) => {
    // for saving
    const blob = new Blob([dataExport], { type: 'application/octet-stream' });
    //const blob = new Blob([dataExport], { type: fileName.toLowerCase().endsWith('.pdf') ? 'application/pdf' : 'application/octet-stream' });
    const url = URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    a.style.display = 'none'
    document.body.appendChild(a)
    
    a.click()

    // Cleanup
    setTimeout(() => {
        document.body.removeChild(a)
        URL.revokeObjectURL(url)
    }, 100)
}
