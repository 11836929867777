import axios from "axios"

export const getGroupsApi = async () => {
  const response = await axios.get('/accounts/groups/', {withCredentials: true});
  return response.status === 200 ? response.data : null
}

export const getProfileApi = async (userId) => {
  const response = await axios.get(`/accounts/${userId}/`, {withCredentials: true})
  return response.status === 200 ? { profile: response.data } : { noAuth: response?.response?.data }
}

export const getUsersApi = async (data = {}) => {
  let response
  if (data?.entityId)
    response = await axios.get(`/accounts/by-entity/${data.entityId}`, { ...data }, {withCredentials: true})
  else
    response = await axios.get('/accounts/by-entity/', { ...data },  {withCredentials: true})
  //return response.status === 200 ? Array.isArray(response.data) ? response.data : null : null
  return response.status === 200 ? response.data : []
}

export const getSuppliersApi = async (data = {}) => {
  let response
  if (data?.supplierId)
    response = await axios.get(`/accounts/by-supplier/${data.supplierId}`, { ...data }, {withCredentials: true})
  else
    response = await axios.get('/accounts/by-supplier/', { ...data }, {withCredentials: true})
  //return response.status === 200 ? Array.isArray(response.data) ? response.data : null : null
  return response.status === 200 ? response.data : []
}

export const getManagersApi = async () => {
  const  response = await axios.get('/accounts/managers/', {withCredentials: true})
  return response.status === 200 ? response.data : []
}

// POST to create if no id property on data
// PATCH to update if id property on data
// return whole request object for custom handling
export const setUsersApi = async (data) => {
  if (data.hasOwnProperty('id'))
    return await axios.patch(`/accounts/${data.id}/`, { ...data }, {withCredentials: true})
  else
    return await axios.post('/accounts/', { ...data }, {withCredentials: true})
}

// reset password and verify
// POST ${API_URL}/ - request a reset password token by using the email parameter
export const requestResetPasswordApi = async (email) => {
    return await axios.post('/accounts/password_reset/', { email: email }, {withCredentials: true})
}
// POST ${API_URL}/confirm/ - using a valid token, the users password is set to the provided password
export const resetPasswordApi = async (token, password) => {
  return await axios.post('/accounts/password_reset/confirm/', { token: token, password: password }, {withCredentials: true})
}
// POST ${API_URL}/validate_token/ - will return a 200 if a given token is valid
export const validaeResetPasswordApi = async (email) => {
  return await axios.post('/accounts/password_reset/validate_token/', { token: email }, {withCredentials: true})
}

// LOGS
export const getUserLogsApi = async (data = {}) => {
  const response = await axios.get(`/accounts/logs/${data?.userId ?? ''}`, { ...data }, {withCredentials: true})
  //const response = await axios.get(`/accounts/logs/`, {withCredentials: true})
  return response.status === 200 ? response.data : null
}

