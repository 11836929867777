import { useState } from "react"
import { 
    Stack, 
    Heading, 
    Section, 
    Modal,
} from '@carbon/react';
import { useAuth } from '../contexts/AuthContext';
import { UiShell, scrollToTop } from '../containers/Template'
import { DataCRUD } from '../containers/DataCRUD'
import { MediaManager } from '../components/MediaManager'
import { SupplierNavigation } from '../components/SupplierNavigation'

// api imports
import { getQuoteApi, consolidadeQuoteApi, sendQuoteOrderApi, refuseQuoteOrderApi } from '../utils/api/quotes'

// utils
import { maskValue } from '../utils/inputMasks'

// datacrud setup
import { ordersDataCRUD, toQuoteDataCRUD } from '../datacruds/compramed-quotes'

// move to a strings datafile?
const confirmQuoteMsg = "A cotação selecionada será enviada ao ente público para apreciação pelo gestor. Esta ação não pode ser desfeita. Deseja continuar?"

const confirmSendProductsMsg = "A Ordem de Serviço selecionada será marcada como em trajeto para o seu destino. Antes de enviar, confirme se todos os documentos obrigatórios, como Nota Fiscal, estão anexados. Esta ação não pode ser desfeita. Deseja continuar?"

const confirmRefuseOrderMsg = "Você está prestes a DESISTIR de fornecer os produtos do Pedido selecionado. Ao desistir você concorda em ceder o fornecimento a outros concorrentes. Esta ação não pode ser desfeita.  Deseja mesmo DESISTIR?"

// this screen needs to diferentiate staffs from suppliers, suppliers always use own id to query data, while staffs have a supplier navigation to select and view anyones view
const CompraMedQuotes = () => {
    const [ selectedSupplier, setSelectedSupplier ] = useState(null)
    const [ totalQuote, setTotalQuote ] = useState(0)
    const [ quoteStatus, setQuoteStatus ] = useState(0)
    const [ quoteComplete, setQuoteComplete ] = useState(false)
    const [ fiscalDocs, setFiscalDocs ] = useState({})
    const [ selectedOrder, setSelectedOrder ] = useState(null)
    const [ quoteDataId, setQuoteDataId ] = useState(null)
    const [ viewQuote, setViewQuote ] = useState(false)
    const [ reloadDataCRUD, setReloadDataCRUD ] = useState(0);
    const [ confirmQuoteModal, setConfirmQuoteModal ] = useState(0)
    const [ confirmSentModal, setConfirmSentModal ] = useState(0)
    const [ confirmRefuseorderModal, setConfirmRefuseorderModal ] = useState(0)
    const { user } = useAuth();
    const userSupplierLink = user.supplier_link
    const canChange = user.permissions.includes('change_quotation') && !quoteStatus
    
    // quote DataCRUD callbacks
    const onUpdateQuote = async (data) => {
        // sum all quote items
        const sum = data.reduce((acc, val) => acc + parseFloat(val.total.replaceAll(".","").replaceAll(",",".")), 0)
        setTotalQuote(sum)
        // check if all items were quoted...
        //setQuoteComplete(data.reduce((acc, val) => acc && parseFloat(val.price.replaceAll(".","").replaceAll(",",".")) > 0, true))
        // instead now checks if at least one item is quoted
        setQuoteComplete(data.some(val => parseFloat(val.price.replaceAll(".","").replaceAll(",",".")) > 0))
        // retrieve the whole quote to check status for edit mode
        const quoteData = await getQuoteApi({ orderId: selectedOrder?.id, supplierId: userSupplierLink ?? selectedSupplier })
        setQuoteStatus(quoteData.status)
        setQuoteDataId(quoteData.id)
    }

    // on each form display request we callback with the form data for orders to quote
    const onFormDisplay = async (data, formEditMode) => {
        // no data we hide product items
        if (data === null) {
            //setSaveItems(null)
            setSelectedOrder(null)
            //setSelectedOrderId(null)
            setQuoteDataId(null)
            setFiscalDocs({})
        // if we have order items show then on product list
        } else if (data?.['order_items']) {
            //setSelectedOrder(data.number)
            //setSelectedOrderId(data.id)
            setSelectedOrder(data)
            setQuoteDataId(data?.my_quote)
        } else {
            setSelectedOrder(null)
            //setSelectedOrder(data)
            //setSelectedOrderId(null)
            setQuoteDataId(data?.my_quote)
            setFiscalDocs({})
        }
    }
    
    // Actions callback: based on formActions
    const onDataAction = (action, data) => {
        //setOrderDataId(dataId)
        switch (action) {
            case "authorized": 
            case "viewQuote": 
            case "viewItems": 
                //setQuoteDataId(data?.my_quote)
                // retrieve quote data via quote, using order id and supplier id
                setViewQuote(true)
                // scroll screen to the top
                scrollToTop()
            break
            case "refuseOrder":
                setQuoteDataId(data.my_quote)
                setConfirmRefuseorderModal(1)
            break
            case "toQuote": 
                //setSelectedOrderId(data.id)
                // open modal and ask if user are sure about the action
                setConfirmQuoteModal(1)
            break
            case "ordered": 
                //"context": { "status": 3 },
                //"label": "Aguardando Envio",
                // call modal asking for confirmation on sent products
                // reset files
                setFiscalDocs({})
                // resolve on onConfirmSendAction
                setConfirmSentModal(1)
            break
            case "shipping": 
                //"context": { "status": 4 },
                //"label": "Marcar Pedido como Recebido",
                /* changeOrderStatus(dataId, 5) */
            break
            case "received": // disabled, never reachs here
                //"context": { "status": 5 },
                //"label": "Recebido",
            break
            case "canceled": // disabled, never reachs here
                //"context": { "status": 6 },
                //"label": "Cancelado",
            break;
            default:

            break
        }
    }

    const onSaveData = (result) => {
        if (result?.error) {
            console.log(`Error: ${result}`)
        } else {
            console.log(`Data saved: ${result}`)
            scrollToTop()
        }
    }

    const onNavChange = (supplierId) => {
        setSelectedSupplier(supplierId)
        setReloadDataCRUD(reloadDataCRUD+1)
    }

    const onConfirmQuoteAction = async (e) => {
        // change quote status to consolidated
        consolidadeQuoteApi(quoteDataId)
        // close quote view
        setViewQuote(false)
        setConfirmQuoteModal(0)
        setReloadDataCRUD(reloadDataCRUD+1)
    }

    const onConfirmSendAction = async (e) => {
        //if (Object.keys(fiscalDocs).length > 0) {
            // mark order as sent
            sendQuoteOrderApi(quoteDataId, fiscalDocs)
            setConfirmSentModal(0)
            setReloadDataCRUD(reloadDataCRUD+1)
            // get back to main view
            setViewQuote(false)
        //} else {
            // throws error
        //}
    }

    const onConfirmRefuseOrderAction = async (e) => {
        // mark order as refused
        await refuseQuoteOrderApi(quoteDataId)
        setConfirmRefuseorderModal(0)
        setReloadDataCRUD(reloadDataCRUD+1)
    }

    //
    // multimedia handling via attachements key
    //
    const onMediaDelete = (attachmentName) => {
        // check if attachmentId exists in any fiscalDocs['attachments'] object as parameter, if so, remove it! and update
        const updatedAttachments = fiscalDocs.attachments.filter(
            (attachment) => attachment.doc.name !== attachmentName
        )

        setFiscalDocs({
            ...fiscalDocs,
            attachments: updatedAttachments
        }) 
    }

    const handleChangeFile = (mediaFileObjects) => {
        setFiscalDocs({
            ...fiscalDocs,
            attachments: [...(fiscalDocs.attachments || []), ...(mediaFileObjects || [])]
        }) 
    }

    return (
        <UiShell>
            <Stack gap={8}>
                {confirmQuoteModal !== 0 &&
                    <Modal 
                        open={confirmQuoteModal}
                        modalHeading="Consolidar e Enviar Cotação"
                        primaryButtonText="Enviar Cotação" 
                        secondaryButtonText="Cancelar" 
                        onRequestSubmit={onConfirmQuoteAction} 
                        onRequestClose={() => setConfirmQuoteModal(0)}
                    >
                        <p>{confirmQuoteMsg}</p>
                    </Modal>
                }
                {confirmSentModal !== 0 &&
                    <Modal 
                        open={confirmSentModal}
                        /* primaryButtonDisabled={Object.keys(fiscalDocs).length === 0} */
                        modalHeading="Enviar Remessa"
                        /* modalLabel="Enviar Remessa"  */
                        primaryButtonText="Enviar Remessa" 
                        secondaryButtonText="Cancelar" 
                        onRequestSubmit={onConfirmSendAction} 
                        onRequestClose={() => setConfirmSentModal(0)}
                    >                  
                        <p>{confirmSendProductsMsg}</p>
                        <br/>
                        <MediaManager
                            label={"Documentos Fiscais"}
                            helper={"Tamanho máximo de arquivo é 2Mb. Formatos suportados: Doc, PDF."}
                            type={'doc'}
                            buttonKind="tertiary"
                            disabled={fiscalDocs?.attachments?.length === 3}
                            onAdd={handleChangeFile}
                            onDelete={onMediaDelete}
                            medias={fiscalDocs.attachments}
                            readMode={false}
                        />    
                </Modal>
                }
                {confirmRefuseorderModal !== 0 &&
                    <Modal 
                        open={confirmRefuseorderModal}
                        modalHeading="Desistência de Fornecimento"
                        primaryButtonText="Sim" 
                        secondaryButtonText="Não" 
                        onRequestSubmit={onConfirmRefuseOrderAction} 
                        onRequestClose={() => setConfirmRefuseorderModal(0)}
                    >                  
                        <p>{confirmRefuseOrderMsg}</p>
                </Modal>
                }
                {viewQuote === false && (
                    <>
                        <Section>
                            <Heading className="primary">Cotações</Heading>
                            <p>Visualize cotações recebidas e envie propostas para avaliação.</p>
                        </Section>
                        {!userSupplierLink &&
                            <Section>
                                <SupplierNavigation 
                                    selectedSupplier={selectedSupplier}
                                    onChange={onNavChange}
                                />
                            </Section>
                        }
                        <Section>
                            <DataCRUD 
                                title={'Cotações'} 
                                desc={'Todos os Cadastros'} 

                                {...ordersDataCRUD}

                                getApiParam={{ supplierId: userSupplierLink ?? selectedSupplier }}

                                onFormDisplay={onFormDisplay}
                                canCallActions={!(userSupplierLink === null && selectedSupplier === null)}
                                canAdd={false}
                                canChange={false}
                                permissions={user.permissions}

                                onAction={onDataAction}
                                forceReload={reloadDataCRUD}
                            /> 
                        </Section>
                    </>
                )}
                {viewQuote === true &&
                    <>
                        <Section>
                            <Stack gap={5}>
                                <Heading className="primary">{`Pedido ${selectedOrder?.number}`}</Heading>
                                <p>Cotação</p>
                                <div><a href="#" onClick={() => setViewQuote(false)}>Voltar para Lista</a> / {`Pedido ${selectedOrder?.number}`}</div>
                                { selectedOrder?.status <= 2 && 
                                    <p>Itens cotados com Valor Unitário 0,00 (zero) serão ignorados e a cotação enviada será parcial, desconsiderando o item.</p>
                                }
                            </Stack>
                        </Section>
                        <Section>
                            <DataCRUD 
                                title={`Pedido ${selectedOrder?.number}`} 
                                desc={`Valor Total: ${maskValue(totalQuote.toString().replaceAll(","," ").replaceAll(".",",").replaceAll(" ", "."), 'money')}`} 
                                                                
                                {...toQuoteDataCRUD}
                                
                                getApiParam={{ orderId: selectedOrder?.id, supplierId: userSupplierLink ?? selectedSupplier }}
                                getApiKey={selectedOrder?.status <= 2 ? 'quote_items' : 'winner_quote_items'}
                                getApiFlatData={true}
                                canChange={canChange}
                                canCallActions={quoteComplete}
                                canAdd={false}
                                onUpdateDataGrid={onUpdateQuote}
                                onGridAction={onDataAction}
                                permissions={user.permissions}
                                
                                onSave={onSaveData}
                                onAction={onDataAction}
                                forceReload={reloadDataCRUD}
                                localGridData={true}
                            /> 
                        </Section>
                    </>
                }
            </Stack>
        </UiShell>
    )
}

export default CompraMedQuotes