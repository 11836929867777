import axios from "axios";

// GET: keep simple and return only data or null
export const getBalanceApi = async (data = {}) => {
  const response = await axios.get('/balance/', { ...data }, { withCredentials: true })
  return response.status === 200 ? response.data : null
}

// POST to create if no id property on data
// PATCH to update if id property on data
// return whole request object for custom handling
export const setBalanceApi = async (data) => {
  if (data.hasOwnProperty('id'))
    return await axios.patch(`/balance/${data.id}/`, { ...data }, { headers: {
      'Content-Type': 'multipart/form-data',
    }, withCredentials: true})
  else
    return await axios.post('/balance/', { ...data }, { headers: {
      'Content-Type': 'multipart/form-data',
    }, withCredentials: true})
}

export const getBalanceStats = async () => {
  const response = await axios.get('/balance/stats/', { withCredentials: true })
  return response.status === 200 ? response.data : null
}

export const getDebtsStats = async (data = {}) => {
  const response = await axios.get('/balance/debts/', { ...data }, { withCredentials: true })
  return response.status === 200 ? response.data : null
}

export const cancelBalance = async (balance, cancelDesc) => {
  return await axios.patch(`/balance/${balance.id}/`, { "status": 0, "cancel_desc": cancelDesc }, { withCredentials: true})
}

export const restoreBalance = async (balance) => {
  return await axios.patch(`/balance/${balance.id}/`, { "status": 1 }, { withCredentials: true})
}