import { downloadExport } from './downloadData'
import { parseHtmlFormsToJson } from './htmlToJson'

const parseToCSV = (data) => {
    // Helper function to escape CSV values containing commas or double quotes
    const escapeCSVValue = (value) => {
        if (value !== undefined && (value.includes(',') || value.includes('"'))) {
            return `"${value.replace(/"/g, '""')}"`; // Double quotes are escaped by doubling them
        } else {
            return value;
        }
    };

    // Convert the data into CSV format
    let csv = "";

    // Get the header row
    const headers = Object.keys(data[0]);
    csv += headers.map(header => escapeCSVValue(header)).join(',') + '\n';

    // Get the data rows
    data.forEach(item => {
        const values = Object.values(item);
        const formattedValues = values.map(value => escapeCSVValue(value));
        csv += formattedValues.join(',') + '\n';
    });

    return csv;
}

export const csvExporter = (htmlData) => {
    const exporterData = parseHtmlFormsToJson(htmlData, true)

    const csvContent = parseToCSV(exporterData)
    downloadExport(csvContent, 'exported_data.csv')
}
