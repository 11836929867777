import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { 
    Stack, 
    Heading, 
    Section, 
    TextInput,
    Button,
    Modal,
} from '@carbon/react';
import { useAuth } from '../contexts/AuthContext';
import { UiShell } from '../containers/Template'
import { DataCRUD, OpMode } from '../containers/DataCRUD'

// icons
import { HelpDesk, Close } from '@carbon/icons-react';

// api imports
import { getTicketsApi, sendTicketMessageApi, assignTicketApi, closeTicketApi } from '../utils/api/tickets'

// DataManager form setup
const form = [
    [
        {
            label: 'Assunto:',
            helper: '',
            type: 'select',
            data: [
                {
                    name: 'Minha Conta',
                    id: 0
                },
                {
                    name: 'Entes Principais',
                    id: 1
                },
                {
                    name: 'Entidades Vinculadas',
                    id: 2
                },
                {
                    name: 'Operadores Autorizados',
                    id: 3
                },
                {
                    name: 'Produtos',
                    id: 4
                },
                {
                    name: 'Solicitações de Compra',
                    id: 5
                },
                {
                    name: 'Empenho Orçamentário',
                    id: 6
                },
                {
                    name: 'Fornecedores',
                    id: 7
                },
                {
                    name: 'Cotações',
                    id: 8
                },
                {
                    name: 'Notas Fiscais',
                    id: 9
                },
                {
                    name: 'Relatórios',
                    id: 10
                },
                {
                    name: 'Outros',
                    id: 11
                }
            ],
            optionText: 'name',
            key: 'subject'
        },
        {
            label: 'Protocolo',
            helper: '',
            type: 'text',
            key: 'number',
            disabled: true
        }
    ],
    [
        {
            label: 'Support Chat Window',
            helper: '',
            context: { status: [0,1,2] },
            type: 'conversation',
            key: 'messages'
        }
    ],
    [
        {
            label: 'Mensagem:',
            helper: 'Aviso: Suas mensagens serão salvas para fins de auditoria',
            context: { status: [null,0,1] },
            type: 'chatInput',
            key: 'message'
        }
    ],
    [
        {
            label: '',
            helper: '',
            type: 'hiden',
            key: 'status'
        },
        {
            label: '',
            helper: '',
            type: 'hiden',
            key: 'my_responsible'
        },
        {
            label: '',
            helper: '',
            type: 'hiden',
            key: 'my_ticket'
        }
    ]
]

// DataGrid headers and data table formating setup
const dataGridHeader = [
    {
        header: 'Protocolo',
        key: 'number'
    },
    {
        header: 'Usuário',
        key: 'requester_name'
    },
    {
        header: 'Atendente',
        key: 'responsible_name'
    },
    {
        header: 'Assunto',
        key: 'subject_code'
    },
    {
        header: 'Data de Abertura',
        key: 'created_at'
    },
    {
        header: 'Status',
        key: 'status_code'
    }
]

const dataGridSearchBy = ['number', 'requester', 'responsible', 'subject', 'created_at', 'status'] //dataGridHeader.map(item => item.key.endsWith('_code') ? item.key.slice(0, -5) : item.key)

const dataGridFilterBy = [
    { 
        label: 'Abertos',
        query: [
            {
                key: 'status',
                value: 0
            }
        ]
    },
    { 
        label: 'Em Andamento',
        query: [
            {
                key: 'status',
                value: 1
            }
        ]
    },
    { 
        label: 'Finalizados',
        query: [
            {
                key: 'status',
                value: 2
            }
        ]
    }
]


const formActions = {
    // override default add, edit and delete actions: those are not called back
    // they are default handled inside DataCRUD
    "add": {
        "label": "Novo Atendimento",
        "permission": "add_ticket",
        "icon": HelpDesk
    },
    "save": {
        "hide": true
    },
    "cancel": {
        "hide": true
    },
    "cancelTicket": {
        "label": "Cancelar Atendimento",
        "context": { status: 0, my_ticket: true },
        "icon": Close
    },
    "closeTicket": {
        "label": "Finalizar Atendimento",
        "context": { status: 1, my_ticket: true },
        "icon": Close
    },
    "closeTicketSupport": {
        "label": "Finalizar Atendimento",
        "context": { status: 1, my_responsible: true },
        "icon": Close
    },
    // support actions based on my_responsible
    "assignTicket": {
        "label": "Atender",
        "context": { status: [0], my_responsible: false, my_ticket: false },
        "icon": HelpDesk
    }
}

const modalContent = [
    {
        head: "Cancelar Pedido de Suporte",
        text: "Você está prestes a cancelar seu pedido de suporte. Fazendo isto ele não constará na sua lista de atendimentos  Esta ação não pode ser desfeita. Deseja mesmo continuar?",
        button: "Cancelar Pedido de Suporte"
    },
    {
        head: "Finalizar Atendimento",
        text: "Você está prestes a finalizar seu atendimento. Fazendo isto será salvo para fins de auditoria. Esta ação não pode ser desfeita. Deseja mesmo continuar?",
        button: "Finalizar Atendimento"
    }
]

const Support = () => {
    const [ selectedTicket, setSelectedTicket ] = useState(null)
    const [ reloadDataCRUD, setReloadDataCRUD ] = useState(0);
    const [ ticketHandlingModal, setTicketHandlingModal ] = useState(0)
    const { user } = useAuth();
    const isSupport = user.group_code === 'Suporte'
    const canAdd = user.permissions.includes('add_ticket')

    // DataCRUD callbacks

    // on each form display request we callback with the form data
    const onFormDisplay = async (data, formEditMode) => {
        if (formEditMode === OpMode.read && data?.id) {
            setSelectedTicket(data)
        } else if (formEditMode === OpMode.create) {
            setSelectedTicket(null)
        }
    }

    // Actions callback: based on formActions
    const onDataAction = (action, data) => {
        switch (action) {
            case "assignTicket":
                assignTicketApi(data)
                setReloadDataCRUD(reloadDataCRUD+1)
            break
            case "cancelTicket":
                setTicketHandlingModal(1)
            break
            case "closeTicket": 
            case "closeTicketSupport":
                setTicketHandlingModal(2)
            break
        }
    }

    const onSaveData = (result) => {
        if (result?.error) {
            console.log(`Error: ${result}`)
        } else {
            console.log(`Data saved: ${result}`)
        }
    }

    const onCreateData = (result) => {
        // update object data?
        if (result?.error) {
            console.log(`Error: ${result}`)
        } else {
            setSelectedTicket(result)
        }
    }
    
    const onConfirmTicketAction = (e) => {
        if (ticketHandlingModal === 1) {
            closeTicketApi({ id: selectedTicket?.id, cancel: true })
            setReloadDataCRUD(reloadDataCRUD+1)
        } else if (ticketHandlingModal === 2) {
            closeTicketApi({ id: selectedTicket?.id })
            setReloadDataCRUD(reloadDataCRUD+1)
        }
        setTicketHandlingModal(0)
    }
    
    const customTitle = (data) => data?.id ? data.number : 'Selecione Assunto'

    return (
        <UiShell>
            <Stack gap={8}>
                {ticketHandlingModal !== 0 &&
                    <Modal 
                        open={ticketHandlingModal}
                        modalHeading={modalContent[ticketHandlingModal-1].text}
                        modalLabel={modalContent[ticketHandlingModal-1].head}
                        primaryButtonText={modalContent[ticketHandlingModal-1].button}
                        secondaryButtonText="Cancelar" 
                        onRequestSubmit={onConfirmTicketAction} 
                        onRequestClose={() => setTicketHandlingModal(0)}
                    />
                }
                <Section>
                    <Heading className="primary">Atendimentos</Heading>
                    <p>Pedidos de assistência no uso da plataforma.</p>
                </Section>
                <Section>
                    <DataCRUD 
                        title={'Atendimentos'} 
                        desc={'Todos os Registros'} 
                        headers={dataGridHeader} 
                        searchBy={dataGridSearchBy}
                        filters={dataGridFilterBy}

                        getApi={getTicketsApi}
                        formAutoRefresh={5}

                        canAdd={canAdd}
                        canChange={false}
                        canCallActions={true}
                        permissions={user?.permissions}
                        form={form}
                        formTitleKey={'name'}
                        formActions={formActions}
                        formTitleFunc={customTitle}
                        onFormDisplay={onFormDisplay}
                        setApi={sendTicketMessageApi}
                        setApiParam={{ id: selectedTicket?.id }}
                        onSave={onSaveData}
                        onCreate={onCreateData}
                        onAction={onDataAction}
                        forceReload={reloadDataCRUD}
                    /> 
                </Section>
            </Stack>
        </UiShell>
    )
}

export default Support