import { useState, useEffect } from "react";
import { 
    Stack, 
    Section, 
    Tab,
    Tabs,
    TabList,
    TabPanel,
    TabPanels,
} from '@carbon/react';
import { DataCRUD } from '../containers/DataCRUD'

// datacrud setup for compramed and compraserv(this is a shared component between apps)
// CompraMED imports
import { quoteForm as compramedQuoteForm } from '../datacruds/compramed-quotes'
import { 
    quoteItemsGridHeader as compramedQuoteItemsGridHeader, 
    gridQuotesActions as compramedGridQuotesActions, 
    quoteItemsSearchBy, // as compramedQuoteItemsSearchBy 
} from '../datacruds/compramed-orders'

// CompraSERV imports
import { quoteForm as compraservQuoteForm, quoteServiceForm, quoteServiceItemsGridHeader } from '../datacruds/compraserv-quotes'
import { 
    quoteItemsGridHeader as compraservQuoteItemsGridHeader, 
    gridQuotesActions as compraservGridQuotesActions, 
    quoteItemsSearchBy as compraservQuoteItemsSearchBy 
} from '../datacruds/compraserv-orders'

// utils
import { maskValue } from '../utils/inputMasks'

// api imports
import { getQuotesApi } from '../utils/api/quotes'

export const QuotesList = ({ orderId = null, onAction = null, reviewMode = false, isProduct = true, isManagerChoice = false }) => {
    const [ quotesData, setQuotesData ] = useState([])
    const [ singleWinner, setSingleWinner ] = useState(false)
    const appMode = window.APP_MODE
    const quoteFormSetup = appMode === "CompraMED" ? compramedQuoteForm : isProduct ? compraservQuoteForm : quoteServiceForm
    const quoteItemsGridHeaderSetup = appMode === "CompraMED" ? compramedQuoteItemsGridHeader : isProduct ? compraservQuoteItemsGridHeader : quoteServiceItemsGridHeader
    const gridQuotesActions = appMode === "CompraMED" ? compramedGridQuotesActions : compraservGridQuotesActions
    
    const getQuotedData = async (dataId) => {
        const response = await getQuotesApi({ orderId: dataId })

        if (response.length === 0)
            return

        // 1 transform any non quoted item into Indisponivel
        const quotations = response.map(obj => ({
            ...obj,
            quote_items: obj.quote_items.map(item => ({
                ...item,
                price: item.price === "0,00" ? "Indisponível" : item.price,
                total: item.total === "0,00" ? "Indisponível" : item.total
            }))
        }))

        // first we check if this is a mix of winners of a single winner:
        const isSingleWinner = quotations.reduce((acc, e) => acc || e.winner, false)
        setSingleWinner(isSingleWinner)

        if (isSingleWinner) {
            // dont create a mix of best quotes for view...
            setQuotesData(quotations)
        } else {
            // process winner_quote_items and winner_total
            // process loser_quote_items and loser_total
    
            // the generated buy order will triger all quotes winners in case more than one supplier wins
            // each quotation will update his status from 2 to 3... and follow as it was a order status flow
            // a new endpoint needed, will receive the order ID only, updating his status to ordered
            let totalWinner = 0;  // Initialize total winner variable
            let quoteItems = [];  // Initialize quote_items array
            let orderItems = []
    
            quotations.forEach(quotation => {
                totalWinner += parseFloat(quotation.winner_total.replaceAll(".","").replaceAll(",",".")) //parseFloat(quotation.winner_total)

                // Concatenate winner_quote_items to quote_items
                //quoteItems = quoteItems.concat(quotation.winner_quote_items)
                quoteItems = quoteItems.concat(
                    quotation.winner_quote_items.map(item => ({
                      ...item,
                      quote_id: quotation.id,
                      ...(reviewMode ? { quote: quotation.supplier } : {})
                    }))
                  )
                
                // for unvaliable items...
                if (orderItems.length === 0) {
                    orderItems = quotation.quote_items?.map(item => ({
                        ...item,
                        quote: 'Indisponível'
                    }))
                }
            })

            // if there are any desert item we should add to the winnerQuote
            let unvaliableItems = []
            
            if (isProduct) {
                unvaliableItems = orderItems?.filter(item => !quoteItems.some(existingItem => existingItem.item.product.id === item.item.product.id))
            } else { // is_service 
                unvaliableItems = orderItems?.filter(item => !quoteItems.some(existingItem => existingItem.service.service.id === item.service.service.id))
            }
            quoteItems.push(...unvaliableItems)
            
            // is this not a single winner?
            const winnerQuote = {
                quote_items: quoteItems,
                total: totalWinner.toString().replaceAll(","," ").replaceAll(".",",").replaceAll(" ", "."),
                winner: true,
                id: "Multi Ganhadora",
                supplier: "Multi Ganhadora",
                status: 2, // to force context on buy order action "confirmOrder"
                multiple: true
            }

            const loserQuote = quotations.map(obj => ({ ...obj, winner: false }))
    
            setQuotesData([winnerQuote, ...loserQuote])
        }
    }

    useEffect(() => {
        async function fetchData() {
            await getQuotedData(orderId)
        }
        if (orderId)
            fetchData() 
    }, [orderId])

    return (
        <Section>
            <Tabs>
                <TabList aria-label="Lista de Cotações">
                    <Tab>{ isManagerChoice ? 'Seleção do Gestor'  : 'Melhor Preço'}</Tab>
                    <Tab>Outras Cotações</Tab>
                </TabList>
                <TabPanels>
                <TabPanel style={{ padding: '24px 0px 0px 0px'}}>
                    {quotesData?.filter(e => e.winner === true).map((e, idx) => 
                        <DataCRUD 
                            key={idx}
                            title={`Total Cotado: ${maskValue(e.total, 'money')}`} 
                            desc={`Cotação ${reviewMode ? e.supplier : e.id}`} 
                            headers={
                                [ 
                                    ...quoteItemsGridHeaderSetup,
                                    ...(!singleWinner ? [{
                                        header: reviewMode ? 'Fornecedor' : 'Cotação',
                                        key: 'quote'
                                      }] : [])
                                ]
                            } 
                            gridData={e}
                            flatGridData={true}
                            gridDataKey={'quote_items'}
                            gridActions={reviewMode ? undefined : gridQuotesActions}
                            onGridAction={onAction}

                            formTitleKey={isProduct ? 'item_product_name' : 'item_service_name'}
                            form={quoteFormSetup}
                            canAdd={false}
                            canChange={false}
                            localGridData={true}
                            searchBy={quoteItemsSearchBy}
                        /> 
                    )}
                </TabPanel>
                <TabPanel style={{ padding: '24px 0px 0px 0px'}}>
                    <Stack gap={6}>
                        {quotesData?.filter(e => e.winner === false).map((e, idx) => 
                            <DataCRUD 
                                key={idx}
                                title={`Total Cotado: ${maskValue(e.total, 'money')}`} 
                                desc={`Cotação ${reviewMode ? e.supplier : e.id}`} 
                                headers={quoteItemsGridHeaderSetup} 
                                gridData={e}
                                flatGridData={true}
                                gridDataKey={'quote_items'}
                                gridActions={(reviewMode && e.status != 7) ? undefined : gridQuotesActions}
                                onGridAction={onAction}
                                localGridData={true}
                                searchBy={quoteItemsSearchBy}
                            />
                        )}
                    </Stack>
                </TabPanel>
                </TabPanels>
            </Tabs>
        </Section>
    )
}