import { htmlExporterData } from './html'

export const printExporter = (htmlData, convertJson=true) => {
    if (convertJson)
        htmlData = htmlExporterData(htmlData)

    //const htmlRawData = renderToStaticMarkup(htmlData)
    var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')
    WinPrint.document.write(htmlData)
    WinPrint.document.close()
    WinPrint.focus()
    // Wait for 3 seconds before printing
    setTimeout(() => {
        // Print the content
        WinPrint.print()

        // Close the window after printing
        WinPrint.close()
    }, 500) // 500 milliseconds = 0,5 second
}
