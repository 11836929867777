
export const checkActionPermissionContext = (actionData, permissions, contextData) => {
    // Add a first stage filter for permissions, if no permission set, it will be true state
    const hasPermission = actionData.hasOwnProperty('permission') ? permissions.includes(actionData.permission) : true
    if (!actionData.hasOwnProperty('context'))
        return hasPermission

    // Proceed with the context filter only if the permissions check passed
    return hasPermission && Object.entries(actionData.context).every(([key, value]) => {
        const managerDataValue = contextData?.[key]
        
        if (Array.isArray(value)) {
            // Check if the managerData value is in the array
            return managerDataValue !== undefined && value.includes(managerDataValue)
        } else {
            // Check if the managerData value matches the single value
            return managerDataValue !== undefined && managerDataValue === value
        }
    });
}