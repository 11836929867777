
// api imports
import { getLinkedEntitiesApi, setEntitiesApi } from '../utils/api/entities'
import { getStatesApi, getCitiesApi } from '../utils/api/location'
import { getAccessProfilesApi } from '../utils/api/auth'

// DataManager form setup
const form = [
    [
        {
            label: 'CNPJ',
            helper: '',
            type: 'cnpj',
            key: 'cnpj'
        },
        {
            label: 'Nome',
            helper: '',
            type: 'text',
            key: 'name'
        },
        {
            label: 'Subordinada a(o)',
            helper: '',
            type: 'text',
            key: 'parent_name',
            disabled: true
        }
    ],
    [
        {
            label: 'CEP',
            helper: '',
            type: 'cep',
            key: 'cep'
        },
        {
            label: 'Endereço',
            helper: '',
            type: 'text',
            key: 'endereco'
        },
        {
            label: 'Número',
            helper: '',
            type: 'text',
            key: 'numero'
        }
    ],
    [
        {
            label: 'Complemento',
            helper: '',
            type: 'text',
            key: 'complemento'
        },
        {
            label: 'UF',
            helper: '',
            type: 'select',
            fetchApi: getStatesApi,
            optionText: 'abbreviation',
            key: 'uf'
        },
        {
            label: 'Cidade',
            helper: '',
            type: 'select',
            fetchApi: getCitiesApi,
            fetchApiParam: 'uf', // use param from key reference...
            optionText: 'name',
            key: 'cidade'
        }
    ],
    [
        {
            label: 'Despesa Autorizada',
            helper: 'Valor Total Despendido do Contrato',
            type: 'money',
            key: 'contract_outcome',
            disabled: true
        },
        {
            label: 'Telefone',
            helper: '',
            type: 'phone',
            key: 'phone'
        },
        {
            label: 'E-mail',
            helper: '',
            type: 'text',
            key: 'email'
        },   
    ],
    [
        {
            label: 'Permitir Acesso ao Sistema:',
            type: 'select',
            fetchApi: getAccessProfilesApi,
            optionText: 'name',
            key: 'access_time_config'
        },
        {},
        {}
    ]
]

// DataGrid headers and data table formating setup
const dataGridHeader = [
    {
        header: 'CNPJ',
        key: 'cnpj'
    },
    {
        header: 'Nome',
        key: 'name'
    },
    {
        header: 'Subordinada a(o)',
        key: 'parent_name'
    },
    {
        header: 'Despesa Autorizada',
        key: 'contract_outcome',
        type: 'money'
    }
]

const dataGridSearchBy = dataGridHeader.map(item => item.key.endsWith('_code') ? item.key.slice(0, -5) : item.key)



// the main DataCRUD setup
export const linkedPublicEntitiesDataCRUD = {
    headers: dataGridHeader, 
    searchBy: dataGridSearchBy,
    form: form,
    formTitleKey: 'name',
    getApi: getLinkedEntitiesApi,
    setApi: setEntitiesApi
}
