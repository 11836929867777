// JSSoup to convert html based forms into javascript queryable dataset for universal format exporting
import JSSoup from 'jssoup'

// for multi level data objects convertion into string
export const flattenData = (data) => {
    return data.map((item, index) => {
        let values = Object.entries(item).map(([key, value]) => `${key}: ${value}`);
        return `${index + 1}) ${values.join(' / ')}`;
    }).join(' | ')
}

export const parseHtmlFormsToJson = (htmlData, tabularFormating=false) => {
    // jssoup
    const soup = new JSSoup(htmlData)
    const formDataSet = soup.findAll('form', 'formBuilderData')
    
    let data = []
    let tabularData = []
    // goes on each export data
    for (const formData of formDataSet) {
        const formFieldRowData = formData.findAll('div', 'formBuilderDataRow')

        let rowsData = []
        let tabular = {}
        // goers on each row
        for (const formFieldRow of formFieldRowData) {
            const formFieldColumnData = formFieldRow.findAll('div', 'formBuilderDataColumn')

            let columnData = []
            // goes on each column
            for (const field of formFieldColumnData) {
                let fieldData = {}
                
                // component extension
                const datagrid = field.findAll('div', 'formBuilderDataGrid')
                const showcase = field.findAll('div', 'formBuilderDataShowcase')
                const chatdata = field.find('div', 'formBuilderDataChatData')
                const headerData = field.find('h4', 'formDataHeaderLabel')?.contents?.[0]?._text ?? ""
                // common form data
                let labelData = field.find('label')?.contents?.[0]?._text ?? ""
                let inputData = field.find('input')?.attrs?.value ?? ""
                
                // form exceptions first
                // grid data
                if (datagrid.length > 0) {
                    
                    // leave it the grid orders for now, only product list
                    //for (const list of datagrid) {
                        //const componentDataTable = list.findAll('tr')
                        const componentDataTable = datagrid[0].findAll('tr')
                        // first one header: contents
                        // second one data: contents
                        // header
                        const header = []
                        for (const element of componentDataTable?.[0]?.findAll('div', 'cds--table-header-label')) {
                            header.push(element?.contents?.[0]?.contents?.[0]?._text ?? "")
                        }
                        // data
                        const dataset = []
                        for (let i=1; i < componentDataTable?.length; i++) {
                            const datasetContents = componentDataTable?.[i]?.contents
                            const datasetElements = {}
                            for (let j=0; j < datasetContents?.length; j++) {
                                datasetElements[header[j]] = datasetContents[j]?.contents?.[0]?._text ?? ""
                            }
                            dataset.push(datasetElements)
                        }

                        // retrieve title
                        const titleData = field.findAll('div', 'cds--data-table-header')
                        const name = field?.contents?.[0]?.contents?.[0]?.attrs?.name ?? ""
                        const title = titleData?.[0]?.contents?.[0]?.contents?.[0]?._text ?? ""
                        const subTitle = titleData?.[0]?.contents?.[1]?.contents?.[0]?._text ?? ""
                        
                        fieldData = {
                            type: 'gridData',
                            label: `${title} / ${subTitle}`, // should we get the griddata title?
                            value: dataset
                        }
                    //}
                    // tabular 
                    // for tabular values we should flattern data and indexing separating by ,
                    tabular[name] = `${title} / ${subTitle} > ` + flattenData(fieldData.value)
                } else if (chatdata) {
                    // mount chat context into single field
                    let chatData = []
                    for (const msg of chatdata?.contents) {
                        const messageLog = {}
                        messageLog[msg?.contents?.[0]?.text ?? ""] = msg?.contents?.[1]?._text ?? ""
                        chatData.push(messageLog)
                    }

                    fieldData = {
                        type: 'chatdata',
                        label: 'Mensagens',
                        value: chatData
                    }
                    // tabular 
                    tabular['Mensagens'] = flattenData(fieldData.value)
                } else if (headerData) {
                    // headers
                    fieldData = {
                        type: 'headerData',
                        label: headerData,
                        value: headerData
                    }
                } else if (showcase.length > 0) {
                    // retrieve all input data
                    const inputData = []
                    for (const input of showcase) {
                        const labels = input.findAll('label')
                        const inputs = input.findAll('input')
                        //inputData += '\r\n'
                        const dataField = {}
                        for (let i=0; i < labels.length; i++) {
                            const label = labels[i]?.contents?.[0]?._text ?? ""
                            const input = inputs[i]?.attrs?.value ?? ""
                            dataField[label] = input
                            //inputData += `${i===1 ? '[ ' : ''}${label}${i===0 ? ': ' : ''}${input} ${i===1 ? '> ' : ''}${i===2 ? ']' : ''}`
                        }
                        inputData.push(dataField)
                    }

                    fieldData = {
                        type: 'showcase',
                        label: 'Lista de Alterações',
                        value: inputData
                    }
                    // tabular 
                    tabular['Lista de Alterações'] = flattenData(fieldData.value)
                } else if (labelData) {
                    fieldData = {
                        type: 'input',
                        label: labelData,
                        value: inputData
                    }
                    // tabular 
                    tabular[fieldData.label] = fieldData.value
                }
                columnData.push(fieldData)
            }
            rowsData.push(columnData)
        }
        // push exported data
        data.push(rowsData)
        if (Object.keys(tabular).length > 0)
            tabularData.push(tabular)
    }
    
    // tabular or row/grid formating?
    return tabularFormating ? tabularData : data
}
