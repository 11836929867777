import { 
    Stack, 
    Heading, 
    Section, 
} from '@carbon/react'
import { useAuth } from '../contexts/AuthContext'
import { UiShell } from '../containers/Template'
import { DataCRUD } from '../containers/DataCRUD'

// datacrud definitions
import { suppliersDataCRUD } from '../datacruds/suppliers'

const Suppliers = () => {
    const { user } = useAuth()
    const backoffice = process.env.REACT_APP_BACKOFFICE
    const canAdd = (user.permissions.includes('add_supplier') && backoffice === 'True') || user.is_staff
    const canChange = (user.permissions.includes('change_supplier') && backoffice === 'True') || user.is_staff

    // DataCRUD callbacks
    const onSaveData = (result) => {
        if (result?.error) {
            console.log(`Error: ${result}`)
        } else {
            console.log(`Data saved: ${result}`)
        }
    }

    const onCreateData = (result) => {
        // update object data?
        if (result?.error) {
            console.log(`Error: ${result}`)
        } else {
            console.log(`Data created: ${result}`)
        }
    }

    return (
        <UiShell>
            <Stack gap={8}>
                <Section>
                    <Heading className="primary">Fornecedores</Heading>
                    <p>Rede de fornecedores credenciados</p>
                </Section>
                <Section>
                    <DataCRUD 
                        title={'Fornecedores'} 
                        desc={'Todos os cadastros'} 

                        {...suppliersDataCRUD}

                        canAdd={canAdd}
                        canChange={canChange}
                        onSave={onSaveData}
                        onCreate={onCreateData}
                    /> 
                </Section>
            </Stack>
        </UiShell>
    )
}

export default Suppliers