import axios from "axios"
// api imports
import { refreshApi, clearToken } from '../utils/api/auth'

/* let refreshToken = false;

axios.interceptors.response.use(resp => resp, async error => {
    // && (error.config.url !== '/auth/token/refresh/' || error.config.url !== '/auth/token/')
    if ((error.response.status === 401 && refreshToken)) {
        refreshToken = true;
        if (refreshApi())
            return axios(error.config)
    }
    refreshToken = false;
    return error;
}); */

axios.interceptors.response.use((response) => response, async (error) => {
      const config = error?.config;
      
      if ((error?.response?.status === 401 && !config?.sent) && (config?.url !== "/auth/token/refresh/") && (config?.url !== "/auth/token/")) {
        config.sent = true;
  
        // check access time response
        if (error?.response?.data?.detail) {
          if (!window.location.href.includes('/login')) {
            clearToken()
            window.location.href = '/login'
            return error
          }
        }

        const result = await refreshApi()
        
        if (result) {
            config.headers['Authorization'] = `Bearer ${result?.accessToken}`
            //console.log("401 token refresh")
        }
  
        return axios(config).catch((error) => {
          // try to clear tokens from localStorage and get back to login
          clearToken()
          window.location.href = '/login'
        });
      }


      return error
    }
  )