import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { 
  Button, 
  TextInput, 
  Form, 
  Stack, 
  Heading, 
  Section, 
  FlexGrid, 
  Row, 
  Column,
  Link,
  Checkbox,
  Modal
} from '@carbon/react';
import { useAuth } from '../contexts/AuthContext';

// api imports
import { loginApi } from '../utils/api/auth'
import { requestResetPasswordApi } from '../utils/api/accounts'

const LoginBox = () => {
    const [ email, setEmail ] = useState('')
    const [ password, setPassword ] = useState('')
    const [ validateError, setValidateError] = useState(null)
    const [ forgotPassword, setForgotPassword ] = useState(false)
    const [ resetPasswordSentModal, setResetPasswordSentModal ] = useState(0)
    const navigate = useNavigate()
    const { user, setUser } = useAuth()
    
    // loged users shouldn't visit this page
    useEffect(() => {
      if (user) 
        navigate('/')
    }, [user])

    const onLoginSubmit = async e => {
        e.preventDefault();

        const { userProfile, error, noAuth } = await loginApi(email, password)
        
        if (error) {
          setValidateError(error)
        } else if (noAuth) {
          setValidateError({ general: noAuth.detail })
        } else if (userProfile) {
          setUser(userProfile)
          navigate('/')
        }
    }

    const onResetPasswordSubmit = async e => {
        e.preventDefault();

        const data = await requestResetPasswordApi(email)

        if (data?.status === 200) {
            // even the system returns
            setResetPasswordSentModal(1)
        } else {
            setValidateError(data?.data)
        }
    }

    return(
        <>
            {resetPasswordSentModal !== 0 &&
                <Modal 
                    open={resetPasswordSentModal}
                    modalHeading="Verifique seu e-mail" 
                    primaryButtonText="Fechar" 
                    onRequestSubmit={() => setResetPasswordSentModal(0)} 
                >
                    <p>Dentro de alguns minutos um link para modificar sua senha será enviado para seu e-mail.</p>
                    <p>Em caso de problemas, entre em contato com o nosso suporte.</p>
                </Modal>
            }
            {(forgotPassword === false) &&
                <>
                    <Section level={4} style={{ textAlign: 'center' }}>
                        <Heading>Seja bem-vindo(a)</Heading>
                        <p>Faça sua autenticação para acessar o sistema.</p>
                        {validateError?.general &&
                            <p style={{ color: '#da1e28', marginTop: '15px', marginBottom: '15px' }}>{validateError.general}</p>
                        }
                    </Section>
                    <Form aria-label="login form" onSubmit={onLoginSubmit}>
                        <Stack gap={4}>
                            <TextInput
                                placeholder="Email"
                                labelText='Email'
                                name='email'
                                id='email'
                                type='text'
                                value={email}
                                invalid={validateError?.['email'] ? true : false}
                                invalidText={validateError?.['email']} 
                                onChange={e => setEmail(e.target.value)}
                            />
                            <TextInput
                                name='password'
                                id='password'
                                type="password"
                                placeholder="Senha"
                                labelText='Senha'
                                value={password}
                                invalid={validateError?.['password'] ? true : false}
                                invalidText={validateError?.['password']} 
                                onChange={e => setPassword(e.target.value)}
                            />
                            <Section>
                                <FlexGrid style={{ marginTop: '20px', marginBottom: '12px', paddingInline: '1rem' }}>
                                    <Row>
                                        <Column style={{ textAlign: 'left'}}><Checkbox labelText={`Salvar dados`} id="save-login" /></Column>
                                        <Column style={{ textAlign: 'right'}}><Link href="#" onClick={e => setForgotPassword(true)}>Esqueci minha senha</Link></Column>
                                    </Row>
                                </FlexGrid>
                                <Button type="submit" style={{ width: '100%' }}>
                                    Entrar
                                </Button>
                            </Section>
                        </Stack>
                    </Form>
                </>
            }
            {(forgotPassword === true) &&
                <>
                    <Section level={4} style={{ textAlign: 'center' }}>
                        <Heading>Esqueceu sua senha?</Heading>
                        <p>Enviaremos instruções ao e-mail cadastrado sobre como modificar sua senha.</p>
                    </Section>
                    <Form aria-label="reset password form" onSubmit={onResetPasswordSubmit}>
                        <Stack gap={5}>
                            <TextInput
                                placeholder="Email"
                                labelText='Email'
                                name='email'
                                id='email'
                                type='text'
                                value={email}
                                invalid={validateError?.['email'] ? true : false}
                                invalidText={validateError?.['email']} 
                                onChange={e => setEmail(e.target.value)}
                            />
                            <Button type="submit" style={{ width: '100%' }}>
                                Criar nova senha
                            </Button>
                            <Link href="#" onClick={e => setForgotPassword(false)}>Voltar para Login</Link>
                        </Stack>
                    </Form>
                </>
            }
        </>
      )
  }
  
  export default LoginBox