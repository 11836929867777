// pdf support(try built-in browser solution to html print into pdf directly for better results)
import html2pdf from 'html2pdf.js/dist/html2pdf.min'
import { htmlExporterData } from './html'
import { downloadExport } from './downloadData'

export const pdfExporter = (htmlData) => {
    const htmlDataPage = htmlExporterData(htmlData)
    //const htmlRawData = renderToStaticMarkup(htmlData)

    html2pdf().from(htmlDataPage).output('blob')
        .then(pdfBlob => {
            downloadExport(pdfBlob, 'exported_data.pdf')
        })
        .catch(error => {
            console.error("Error generating PDF:", error)
            // Handle errors appropriately
        })

    //html2pdf().from(htmlRawData).save('exported_data.pdf')
    //const pdfData = html2pdf().from(htmlRawData).save()
    //downloadExport(pdfData, 'exported_data.pdf')
}
