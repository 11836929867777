//import button from 'react-bootstrap/button';
const smallStyle = { width: '120px' }

function Logo({ small }) {
  const mode = window.APP_MODE
  const url = `${process.env.PUBLIC_URL}/img`
  const logoFile = mode ? `logo-${mode}.svg` : 'logo.svg'
  return (
    <img src={`${url}/${logoFile}`} style={small ? smallStyle : undefined} />
  );
}

export default Logo;