import { useEffect, useState } from "react"
import { 
    FileUploader,
    FileUploaderItem,
    TextInput,
    Section,
} from '@carbon/react'

// icons
import { Close } from '@carbon/icons-react';

// medias setup
const MAX_DOC_SIZE = 2000000
const MAX_IMG_SIZE = 2000000

const fileSizeExceedMsg = "O arquivo selecionado excedeu o limite de tamanho e não poderá ser anexado"

export const MediaManager = ({ 
    label="", 
    helper="",
    type, 
    buttonKind=null,
    disabled,
    medias=null,
    showManager=true,
    readMode=true,
    onAdd=null,
    onDelete=null,
}) => {    
    const [ userFeedbackMsg, setUserFeedbackMsg ] = useState(false)

    const onMediaClick = (url) => {
        console.log(url)
        window.open(url, '_blank');
    }

    const onMediaAdd = (e) => {
        //e.preventDefault()

        const { name, files } = e.target;

        if (files.length === 0)
            return;

        // check max size allowed
        //MAX_DOC_SIZE
        //MAX_IMG_SIZE
        if (files[0]?.size > MAX_DOC_SIZE) {
            // shows a pop-up to the user informing
            setUserFeedbackMsg(true)
            return
        }

        // everything ok? remove userFeedback message
        if (userFeedbackMsg)
            setUserFeedbackMsg(false)

        const mediaFileObjects = Object.keys(files).map((key) => ({
            doc: name === 'doc' ? files[key] : null,
            image: name === 'image' ? files[key] : null,
            url: null,
        }));

        onAdd?.(mediaFileObjects)
    }

    const onMediaDelete = (attachmentId, type) => {
        onDelete?.(attachmentId, type)
    }

    const onUrlAdd = () => {
        const videoLink = document.getElementById("video_link").value
        const videoLinkObject = {
            "doc": null,
            "image": null,
            "url": videoLink
        }
        // reset input
        document.getElementById("video_link").value = ""
        onAdd?.([videoLinkObject])
    }

    const parseUrlToFileName = (url, type) => {
        if (type === 'url')
            return url
        return url.split('?')[0].split('/').pop()
    }

    useEffect(() => {
        if (readMode)
            setUserFeedbackMsg(false)
    }, [readMode])
    
    return (
        <Section>
            { userFeedbackMsg && <p style={{ color: '#da1e28', marginTop: '15px', marginBottom: '15px' }}>{fileSizeExceedMsg}</p> }
            <h3 className="cds--file--label" dir="auto">{label}</h3> 
            {(type === 'url' && showManager) &&
                <>
                    <p className="cds--label-description" dir="auto">{helper}</p>
                    <TextInput 
                        id="video_link"
                        onKeyDown={(event) => event.key === 'Enter' ? onUrlAdd() : undefined}
                        disabled={disabled} 
                        style={{ marginBottom: '8px'}} 
                    />
                </>
            }
            { ((type === 'image' || type === 'doc') && showManager) && 
                <FileUploader 
                    id="file_uploader"
                    /* labelTitle={label} */
                    labelDescription={helper}
                    buttonLabel="Adicionar" 
                    buttonKind={buttonKind ? buttonKind : "primary"} 
                    size="md" 
                    filenameStatus="edit" 
                    accept={type === 'image' ? ['.jpg', '.png'] : ['.docx', '.doc', '.pdf']} 
                    multiple={true} 
                    disabled={disabled} 
                    name={type}
                    onChange={onMediaAdd}
                    style={{ marginBottom: '4px'}}
                />
            }
            {medias?.filter(e => e[type]).map((e, idx) => (
                <div key={idx}> 
                    {(e?.id) &&  /* all already uploaded media on backend */
                        <div
                            className="cds--file__selected-file"
                            key={idx}
                            size="md"
                            onClick={evt => onMediaClick(e[type])}
                            style={{ cursor: 'pointer', position: 'relative' }}
                        >
                            {parseUrlToFileName(e[type], type)}
                            {!readMode &&
                                <span 
                                    className="delete-icon" 
                                    onClick={evt => {
                                        evt.stopPropagation(); // Prevent the click from triggering onMediaClick
                                        onMediaDelete(e.id, type);
                                    }}
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        right: '10px',
                                        transform: 'translateY(-50%)',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <Close />
                                </span>
                            }
                        </div>
                    }
                    {(!e?.id) && /* this is for newlly added medias to be uploaded to backend */
                        <div
                            className="cds--file__selected-file"
                            key={idx}
                            size="md"
                            style={{ cursor: 'pointer', position: 'relative', opacity: 0.5 }}
                        >
                            {parseUrlToFileName(e[type]?.name ?? e[type], type)}
                            <span 
                                className="delete-icon" 
                                onClick={evt => {
                                    evt.stopPropagation(); // Prevent the click from triggering onMediaClick
                                    onMediaDelete(e[type]?.name ? String(e[type].name) : String(e[type]), type);
                                }}
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    right: '10px',
                                    transform: 'translateY(-50%)',
                                    cursor: 'pointer'
                                }}
                            >
                                <Close />
                            </span>
                        </div>
                    }
                </div>
            ))}  
        </Section>
    )
}
