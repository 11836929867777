import axios from "axios";

export const getQuoteApi = async ({ orderId=null, supplierId=null }=null) => {
  const response = await axios.get(`/orders/quotations/${orderId}/${supplierId}/`, {withCredentials: true})
  return response.status === 200 ? response.data : null
}

export const getQuotesApi = async ({ orderId=null }=null) => {
  const response = await axios.get(`/orders/quotations/${orderId}`, {withCredentials: true})
  return response.status === 200 ? response.data : null
}

export const getToQuoteOrdersApi = async (data = {}) => {
  let response
  if (data?.supplierId)
    response = await axios.get(`/orders/suppliers/${data.supplierId}`, { ...data }, {withCredentials: true})
  else
    response = await axios.get('/orders/suppliers/', { ...data }, {withCredentials: true})
  return response.status === 200 ? response.data : null
}

export const setQuoteApi = async (data) => {
  return await axios.patch(`/orders/quotation/items/${data.id}/`, { ...data }, {withCredentials: true})
}

export const consolidadeQuoteApi = async (quoteId) => {
  return await axios.post(`/orders/quotation/consolidade/${quoteId}/`, { withCredentials: true})
}

export const refuseQuoteOrderApi = async (quoteId) => {
  return await axios.post(`/orders/quotation/refuse/${quoteId}/`, { withCredentials: true})
}

export const sendQuoteOrderApi = async (quoteId, attachements) => {
  return await axios.post(`/orders/quotation/send/${quoteId}/`, { ...attachements }, { headers: {
    'Content-Type': 'multipart/form-data',
  }, withCredentials: true})
}