import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Button, TextInput, Tile, Stack, Heading, Section, FlexGrid, Row, Column } from '@carbon/react';
import { useAuth } from '../contexts/AuthContext';
import { UiShell } from '../containers/Template'

// api imports
import { getBalanceStats } from '../utils/api/balance'
import { getOrdersStats } from '../utils/api/orders'
import { getPublicEntitiesStats } from '../utils/api/entities'

// utils
import { maskValue } from '../utils/inputMasks'

const Home = () => {
    const { user } = useAuth()
    const [ orderStats, setOrderStats ] = useState({})
    const [ balanceStats, setBalanceStats ] = useState({})
    const [ publicEntitiesStats, setPublicEntitiesStats ] = useState({})

    const publicatedOrders = Number(orderStats?.ordered) + Number(orderStats?.shipped) + Number(orderStats?.received)
    const quotesRequested = Number(orderStats?.requested) + Number(orderStats?.authorized) + Number(orderStats?.quoted) + Number(orderStats?.canceled) + publicatedOrders
    
    const fetchPublicEntitiesStats = async () => {
        const data = await getPublicEntitiesStats()
        setPublicEntitiesStats(data)
    }

    const fetchBalanceStats = async () => {
        const data = await getBalanceStats()
        setBalanceStats(data)
    }

    const fetchOrdersStats = async () => {
        const data = await getOrdersStats()
        setOrderStats(data)
    }

    const fetchStatsData = () => {
        fetchPublicEntitiesStats()
        fetchBalanceStats()
        fetchOrdersStats()
    }

    useEffect(() => {
        fetchStatsData()
    }, [])
    
    return (
        <UiShell>
            <Section>
                <Stack gap={4}>
                    <Heading className="primary">Painel de Administração</Heading>
                    <p>Bem-vindo, {user.name}. Seu último acesso ocorreu em {user.last_login} no endereço IP {user.last_login_ip}</p>
                </Stack>
                {(user?.staff_link || user?.is_staff) &&
                    <Section level={4} style={{ marginTop: '20px' }} >
                        <Heading>Resumo</Heading>
                        <FlexGrid style={{ paddingInline: '0px', marginTop: '20px' }}>
                            <Row>
                                <Column>
                                    <Tile>
                                        <Heading>{maskValue(balanceStats?.balance, 'money')}</Heading>
                                        Saldo Global de Empenho
                                    </Tile>
                                </Column>
                                <Column>
                                    <Tile>
                                        <Heading>{maskValue(balanceStats?.withdraw, 'money')}</Heading>
                                        Valor Total Empenhado
                                    </Tile>
                                </Column>
                                <Column>
                                    <Tile>
                                        <Heading>{publicatedOrders}</Heading>
                                        Ordens de Compra Publicadas
                                    </Tile>
                                </Column>
                            </Row>
                            <Row style={{ marginTop: '1.5rem' }}>
                                <Column>
                                    <Tile>
                                        <Heading>{publicEntitiesStats?.entity_count}</Heading>
                                        Entes Principais Cadastrados
                                    </Tile>
                                </Column>
                                <Column>
                                    <Tile>
                                        <Heading>{publicEntitiesStats?.linked_entity_count}</Heading>
                                        Entidades Vinculadas
                                    </Tile>
                                </Column>
                                <Column>
                                    <Tile>
                                        <Heading>{publicEntitiesStats?.operators_count}</Heading>
                                        Operadores Cadastrados
                                    </Tile>
                                </Column>
                            </Row>
                            <Row style={{ marginTop: '1.5rem' }}>
                                <Column>
                                    <Tile>
                                        <Heading>{orderStats?.products}</Heading>
                                        Produtos Cadastrados
                                    </Tile>
                                </Column>
                                <Column>
                                    <Tile>
                                        <Heading>{quotesRequested}</Heading>
                                        Cotações Solicitadas
                                    </Tile>
                                </Column>
                                <Column>
                                    <Tile>
                                        <Heading>{publicEntitiesStats?.support_count}</Heading>
                                        Suportes Realizados
                                    </Tile>
                                </Column>
                            </Row>
                        </FlexGrid>
                    </Section>
                }
            </Section>
        </UiShell>
    )
}

export default Home