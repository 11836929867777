// api imports
import { getBalanceApi, setBalanceApi } from '../utils/api/balance'

// DataManager form setup
const form = [
    [
        {
            label: 'Número do Empenho',
            helper: '',
            type: 'text',
            key: 'number'
        },
        {
            label: 'Data de Emissão',
            helper: '',
            type: 'date',
            key: 'issue_date'
        },
        {
            label: 'Espécie',
            helper: '',
            type: 'select',
            data: [
                {
                    name: 'Ordinário',
                    id: 0
                },
                {
                    name: 'Estimado',
                    id: 1
                },
                {
                    name: 'Global',
                    id: 2
                }
            ],
            optionText: 'name',
            key: 'balance_type'
        }
    ],
    [
        {
            label: 'Valor Destinado',
            helper: '',
            type: 'money',
            key: 'total'
        },
        {
            label: 'Cadastrado por',
            helper: '',
            type: 'text',
            key: 'creator',
            disabled: true
        },
        {}
    ],
    [
        {
            label: 'Notas',
            helper: '',
            type: 'header',
            disabled: true
        },
    ],
    [
        {
            label: 'Objeto',
            helper: 'Limite de 500 caracteres',
            type: 'longText',
            key: 'object_desc'
        }
    ],
    [
        {
            label: 'Destinação',
            helper: 'Limite de 500 caracteres',
            type: 'longText',
            key: 'destination_desc'
        }
    ],
    [
        {
            label: 'Motivo do Cancelamento',
            helper: 'Limite de 500 caracteres',
            type: 'longText',
            context: { "status": 0 }, // only shows up when status === 0
            key: 'cancel_desc',
            disabled: true
        }
    ],
    [
        {
            label: 'Processamento',
            helper: '',
            type: 'header',
            disabled: true
        },
    ],
    [
        {
            label: 'Data de Cadastro',
            helper: '',
            type: 'text',
            key: 'created_at',
            disabled: true
        },
        {
            label: 'Última alteração em',
            helper: '',
            type: 'text',
            key: 'last_updated',
            disabled: true
        },
        {
            label: 'Status for Actions', // this is needed to keep status id on managerData inside DataCRUD so we can contextualize actions per status id
            helper: '',
            type: 'hidden',
            key: 'status',
            disabled: true
        },
    ],
    [
        {
            label: 'Nota de Empenho',
            helper: '',
            type: 'multimedia',
            key: 'attachments',
            medias: [
                {
                    label: 'Nota de Empenho',
                    helper: 'Tamanho máximo de arquivo é 2Mb. Formatos suportados: Doc, PDF.',
                    key: 'doc'
                },
                {},
                {}
            ]
        }
    ]
]

// DataGrid headers and data table formating setup
const dataGridHeader = [
    {
        header: 'Número do Empenho',
        key: 'number'
    },
    {
        header: 'Data de Emissão',
        key: 'issue_date'
    },
    {
        header: 'Espécie',
        key: 'balance_type_code'
    },
    {
        header: 'Valor do Empenho',
        key: 'total'
    },
    {
        header: 'Status',
        key: 'status_code'
    }
]

const dataGridSearchBy = dataGridHeader.map(item => item.key.endsWith('_code') ? item.key.slice(0, -5) : item.key)

const dataGridFilterBy = [
    { 
        label: 'Todos',
        query: null
    },
    { 
        label: 'Válidos',
        query: [
            {
                key: 'status',
                value: 1
            }
        ]
    },
    { 
        label: 'Cancelados',
        query: [
            {
                key: 'status',
                value: 0
            }
        ]
    }
]

/*

Valor Total do Empenho: Soma de todas as notas de Empenho adicionadas no sistema.
 
Valor total destinado: Soma dos valores das compras (desde o que está em ordem de compra até o que foi recebido). Fazer uma ordem já deve fazer o saldo baixar. Se a compra for cancelada antes da ordem de compra o saldo daquela compra não afeta o empenho.

Saldo: (Valor Total de Empenho) menos (Valor Total Destinado) 

*/

const formActions = {
    "cancel": {
        "context": { "status": 1 }, // the context match to call action on a form, based on form key
        "label": "Cancelar Nota",
        "icon": undefined
    },
    "restore": {
        "context": { "status": 0 },
        "label": "Restaurar Nota",
        "icon": undefined
    }
}


// the main DataCRUD setup
export const balanceContingencyDataCRUD = {
    headers: dataGridHeader, 
    searchBy: dataGridSearchBy,
    filters: dataGridFilterBy,
    form: form,
    formActions: formActions,
    formTitleKey: 'number',
    getApi: getBalanceApi,
    setApi: setBalanceApi
}