import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { 
  Button, 
  TextInput, 
  Form, 
  Stack, 
  Heading, 
  Section, 
  FlexGrid, 
  Row, 
  Column,
  Link,
  Checkbox,
  Modal
} from '@carbon/react';
import { useAuth } from '../contexts/AuthContext';

// api imports
import { loginApi } from '../utils/api/auth'
import { resetPasswordApi } from '../utils/api/accounts'

const ResetPassword = ({ token = null }) => {
    const [ password, setPassword ] = useState('')
    const [ password2, setPassword2 ] = useState('')
    const [ validateError, setValidateError] = useState(null)
    const [ passwordChangeSuccessModal, setPasswordChangeSuccessModal ] = useState(0)
    const navigate = useNavigate()

    const onResetPasswordSubmit = async e => {
        e.preventDefault();

        if (password !== password2) {
            setValidateError({ password: 'As senhas não conferem, por favor tente novamente.' })
            return
        }
        // check if passwords matches
        const data = await resetPasswordApi(token, password)
        
        if (data?.status === 200) {
            // even the system returns
            setPasswordChangeSuccessModal(1)
        } else {
            setValidateError(data?.data)
        }
    }

    return(
        <>
            {passwordChangeSuccessModal !== 0 &&
                <Modal 
                    open={passwordChangeSuccessModal}
                    modalHeading="Senha Modificada com Sucesso" 
                    primaryButtonText="Fechar" 
                    onRequestSubmit={() => navigate('/')} 
                >
                    <p>Você já pode acessar o sistema com suas novas credenciais.</p>
                </Modal>
            }
            <Section level={4} style={{ textAlign: 'center' }}>
                <Heading>Modificação de Senha</Heading>
                <p>Crie uma nova senha para sua conta.</p>
            </Section>
            <Form aria-label="login form" onSubmit={onResetPasswordSubmit}>
                <Stack gap={4}>
                    <TextInput
                        name='password'
                        id='password'
                        type="password"
                        placeholder="Nova Senha"
                        labelText='Nova Senha'
                        value={password}
                        invalid={validateError?.['password'] ? true : false}
                        invalidText={validateError?.['password']} 
                        onChange={e => setPassword(e.target.value)}
                    />
                    <TextInput
                        name='password2'
                        id='password2'
                        type="password"
                        placeholder="Repita sua Nova Senha"
                        labelText='Repita sua Nova Senha'
                        value={password2}
                        onChange={e => setPassword2(e.target.value)}
                    />
                    <Section style={{ marginTop: '15px' }}>
                        <Stack gap={4}>
                            <Column style={{ textAlign: 'left'}}><Link href="/login">Voltar para Login</Link></Column>
                            <Button type="submit" style={{ width: '100%' }}>
                            Salvar Minha Nova Senha
                            </Button>
                        </Stack>
                    </Section>
                </Stack>
            </Form>
        </>
      )
  }
  
  export default ResetPassword