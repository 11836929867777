import axios from "axios";

// GET: keep simple and return only data or null
export const getStatesApi = async () => {
  const response = await axios.get('/location/states/', {withCredentials: true})
  return response.status === 200 ? response.data : null
}

export const getCitiesApi = async (stateId) => {
  if (!stateId) return null
  const response = await axios.get(`/location/cities/${stateId}`, {withCredentials: true})
  return response.status === 200 ? response.data : null
}

// cep auto copmlete support
export const getAddressByCep = async (cep) => {
  if (!cep) return null
  const response = await axios.get(`/location/cep/${cep}`, {withCredentials: true})
  return response.status === 200 ? response.data : null
}