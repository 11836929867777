export const updateObjectById = (objectList, newObject, id) => {
    const newList = objectList.map(obj =>
        obj.id === id ? { ...newObject, id } : obj
    )

    // If the object with the specified id is not found, add the new object to the array
    if (!newList.some(obj => obj.id === id)) {
        newList.push({ ...newObject, id });
    }

    return newList
}

export const filterObjectByKeyValue = (objectList, key, value) => {
    return objectList.filter(obj => obj[key] === value)
}

// flattern
export const flattenObject = (obj, parentKey = "") => {
    let result = {};

    for (const key in obj) {
        const newKey = parentKey ? `${parentKey}_${key}` : key;

        if (typeof obj[key] === 'object' && obj[key] !== null) {
            const nestedResult = flattenObject(obj[key], newKey);
            result = { ...result, ...nestedResult };
        } else {
            result[newKey] = obj[key];
        }
    }

    return result;
}

export const flattenArrayOfObjects = (array) => {
    return array.map((obj) => flattenObject(obj));
}

export const deepEqual = (obj1, obj2) => {
    if (obj1 === obj2) return true

    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) return false

    const keys1 = Object.keys(obj1)
    const keys2 = Object.keys(obj2)

    if (keys1.length !== keys2.length) return false

    for (const key of keys1) {
        if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
            return false
        }
    }

    return true
}