import { Heading, Section, Stack } from '@carbon/react';
import { useAuth } from '../contexts/AuthContext';
import { UiShell } from '../containers/Template'
import { DataCRUD } from '../containers/DataCRUD'
import { UserLogs } from '../containers/UserLogs'

// api imports
import { setUsersApi, getUserLogsApi } from '../utils/api/accounts'

// DataManager form setup
const form = [
    [
        {
            label: 'Nome',
            helper: '',
            type: 'text',
            key: 'first_name'
        },
        {
            label: 'Sobrenome',
            helper: '',
            type: 'text',
            key: 'last_name'
        },
        {
            label: 'Nivel de Acesso',
            helper: '',
            type: 'text',
            key: 'group_code',
            disabled: true
        }
    ],
    [
        {
            label: 'Status',
            helper: '',
            type: 'text',
            key: 'user_status',
            disabled: true
        },
        {
            label: 'ID',
            helper: '',
            type: 'text',
            key: 'id',
            disabled: true
        },
        {
            label: 'Email',
            helper: '',
            type: 'text',
            key: 'email'
        }
    ],
    [
        {
            label: 'Senha',
            helper: '',
            type: 'password',
            key: 'password1'
        },
        {
            label: 'Confirmacao de Senha',
            helper: '',
            type: 'password',
            key: 'password2'
        },
        {
        
            label: 'Cadastrado em',
            helper: '',
            type: 'text',
            key: 'created_at',
            disabled: true
        }
    ]
]

const UserProfile = () => {
    const { user, setUser } = useAuth()

    // DataCRUD callbacks
    const onSaveData = (result) => {
        if (result?.error) {
            console.log(`Error: ${result}`)
        } else {
            setUser(result)
            console.log(`Data saved: ${result}`)
        }
    }

    return (
        <UiShell>
            <Stack gap={8}>
                <Section>
                    <Stack gap={4}>
                        <Heading className="primary">Perfil</Heading>
                        <p>Bem-vindo, {user.name}. Seu último acesso ocorreu em {user.last_login} no endereço IP {user.last_login_ip}</p>
                    </Stack>
                </Section>
                <Section>
                    <DataCRUD 
                        form={form}
                        formData={user}
                        formTitle={user.name}
                        setApi={setUsersApi}
                        setApiParam={{ id: user.id }}
                        onSave={onSaveData}
                        canChange={true}
                    /> 
                </Section>
                <UserLogs userData={user} />
            </Stack>
        </UiShell>
    )
}

export default UserProfile