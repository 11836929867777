import React, { useState, useEffect } from 'react'
import { 
  Select, 
  SelectItem, 
  Heading, 
  Section,
  Stack
} from '@carbon/react'

// api imports
import { getUserManualDataApi, USER_MANUAL_URL } from '../utils/api/user-manual'

// componet version
function parseJSONToJSX(jsonData) {
  return jsonData.map((obj, index) => {
      switch (obj.type) {
          case 'h1':
          case 'h2':
          case 'h3':
          case 'h4':
          case 'h5':
          case 'h6':
            return React.createElement('h4', { key: index, className: "primary" }, obj.children ? parseJSONToJSX(obj.children) : null);
          case 'p':
              return React.createElement('p', { key: index }, obj.children ? parseJSONToJSX(obj.children) : null);
          case 'ul':
              return React.createElement('ul', { key: index }, obj.children ? parseJSONToJSX(obj.children) : null);
          case 'ol':
              return React.createElement('ol', { key: index }, obj.children ? parseJSONToJSX(obj.children) : null);
          case 'li':
              return React.createElement('li', { key: index }, obj.children ? parseJSONToJSX(obj.children) : null);
          case 'a':
            return React.createElement('a', { key: index, href: obj.url }, obj.children ? parseJSONToJSX(obj.children) : null);
          case 'img':
              return React.createElement('img', { key: index, src: `${USER_MANUAL_URL}${obj.url}`, alt: obj.caption || '' });
          case 'code_block':
              return React.createElement('pre', { key: index }, React.createElement('code', { className: obj.lang }, obj.value));
          case 'blockquote':
              return React.createElement('blockquote', { key: index }, obj.children ? parseJSONToJSX(obj.children) : null);
          case 'text':
              return obj.bold ? <b key={index}>{obj.text}</b> : obj.code ? <code key={index}>{obj.text}</code> : obj.italic ? <i key={index}>{obj.text}</i> : obj.text;
          default:
            return null; // Handle unsupported types
      }
  });
}

const CmsLoader = () => {
  const [data, setData] = useState(null)
  const [selectedDoc, setSelectedDoc] = useState(null)
  const [error, setError] = useState(null)
  const [manualNav, setManualNav] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      // we fetch all documentation over a collection at once
      const manualData = await getUserManualDataApi('compramed')
      const responseData = manualData?.map(e => e.node )

      // set first one as selectedDoc
      setSelectedDoc(responseData?.[0])
      setData(responseData)

      // for now dont use nave query api...
      setManualNav(responseData)
    }

    fetchData()
  }, [])
  
  const handleDocChange = (e) => {
    // find doc inside data
    const doc = data.find(d => d.id === e.target.value)
    setSelectedDoc(doc)
  }
  
  return (
    <Stack gap={8}>
      <Section>
        <Select
            id="manualNav"
            name="manualNav"
            labelText="Navegação"
            onChange={handleDocChange}
            defaultValue={selectedDoc?.id}
        >
          {manualNav?.map?.((e, idx) => (
              <SelectItem selected={e.id === selectedDoc.id} key={idx} value={e.id} text={e.title} />
          ))}
        </Select>
      </Section>
      {selectedDoc && (
        <Section level={3}>
          <Stack gap={5}>
            <Heading className="primary">{selectedDoc.title}</Heading>
            {selectedDoc?.description !== '' && <p>{selectedDoc.description}</p>}
            <div />
            {parseJSONToJSX(selectedDoc.body.children)}
          </Stack>
        </Section>
      )}
    </Stack>
  )
}

export default CmsLoader;