import { Heading, Section, Stack} from '@carbon/react'
import { UiShell } from '../containers/Template'
import CmsLoader from '../components/CmsLoader'

// api imports
//...

const UserManual = () => {
    
    return (
        <UiShell>
            <Stack gap={8}>
                <Section>
                    <Heading className="primary">Manual do Usuário</Heading>
                    <p>Como usar a plataforma e solucionar dúvidas frequentes.</p>
                </Section>
                <Section>
                    <CmsLoader />
                </Section>
            </Stack>
        </UiShell>
    )
}

export default UserManual