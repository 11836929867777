import IMask from 'imask';

// Used mainly on FormBuilder
export const inputMasks = {
    'cnpj': { 
        mask: "00.000.000/0000-00" 
    },
    'cep': { 
        mask: "00000-000" 
    },
    'phone': { 
        mask: '(00) 00000-0000' 
    },
    'money': { 
        mask: 'R$ num',
        blocks: {
            num: {
                mask: Number, 
                scale: 2,
                thousandsSeparator: '.', 
                radix: ',',  
                mapToRadix: ['.']
            }
        }
    },
    'percentage': {
        mask: Number, 
        scale: 2,
        radix: '.',  
        mapToRadix: [','], 
        min: 0.00,
        max: 100.00
    }
}

export const maskValue = (value, type) => {
    const maskedElement = IMask.createMask(inputMasks?.[type])
    maskedElement.resolve(String(value))
    return maskedElement.value
}
  
export const unmaskValue = (maskedValue, type) => {
    const maskedElement = IMask.createMask(inputMasks?.[type])
    maskedElement.resolve(String(maskedValue))
    return maskedElement.unmaskedValue
}
