import axios from 'axios'
import { getProfileApi } from './accounts'
import { decodeToken } from '../jwt-decode'

export const loginApi = async (email, password) => {
    try {
        const user = {
            email: email,
            password: password
        };

        const response = await axios.post('/auth/token/', user ,{ headers: {
            'Content-Type': 'application/json'
        }}, {withCredentials: true});

        const data = response?.response ? response.response.data : response?.data
        const status = response?.response ? response.response.status : response?.status

        if (status === 200) {
          // set token data into localStorage
          localStorage.setItem('access_token', data.access);
          localStorage.setItem('refresh_token', data.refresh);
          axios.defaults.headers.common['Authorization'] = `Bearer ${data['access']}`;

          // retrieve user profile
          const decodedToken = decodeToken(data.access)

          // do we have user_id?
          if (!decodedToken?.hasOwnProperty('user_id')) return null
          const userId = decodedToken.user_id
          // query account profile using user_id
          const { profile, noAuth } = await getProfileApi(userId)
          
          if (!profile) 
            return { noAuth: noAuth }
          return { userProfile: profile }
        } else if (status === 401) {
          //console.log('not authorized account not valid')
          return { noAuth: data }
        } else if (status === 400) {
          //console.log('bad request')
          return { error: data }
        }
    } catch (e) {
        console.log('logout error')
    }
    return {}
}

export const refreshApi = async () => {
  const response = await axios.post('/auth/token/refresh/', {
    refresh: localStorage.getItem('refresh_token')
  }, {
    headers: {
      'Content-Type': 'application/json',
    }
  },{withCredentials: true});

  if (response.status === 200) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${response.data['access']}`;
      localStorage.setItem('access_token', response.data['access']);
      localStorage.setItem('refresh_token', response.data['refresh']);
      const refreshData = {
        accessToken: response.data['access'],
        refreshToken: response.data['refresh']
      }
      return refreshData
  } else {
    return null
  }
}

export const logoutApi = async () => {
  try {
      //const {data} = await axios.post('/auth/logout/',{
      await axios.post('/auth/logout/',{
          refresh_token:localStorage.getItem('refresh_token')
      } ,{headers: {
          'Content-Type': 'application/json'
      }}, {withCredentials: true})

      //localStorage.clear()
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
      axios.defaults.headers.common['Authorization'] = null
  } catch (e) {
      console.log('logout error')
  }
}

export const clearToken = () => {
  localStorage.removeItem('access_token')
  localStorage.removeItem('refresh_token')
  axios.defaults.headers.common['Authorization'] = null
}

export const getAccessProfilesApi = async () => {
  const response = await axios.get('/auth/access_profiles/', {withCredentials: true})
  return response.status === 200 ? response.data : null
}

