import { useState } from "react"
import { 
    Stack, 
    Heading, 
    Section, 
    Tab,
    Tabs,
    TabList
} from '@carbon/react';
import { useAuth } from '../contexts/AuthContext';
import { UiShell } from '../containers/Template'
import { DataCRUD } from '../containers/DataCRUD'

// datacrud setup
import { servicesDataCRUD } from '../datacruds/services'
import { goodsDataCRUD } from '../datacruds/goods'

const Services = () => {
    const [ type, setType ] = useState(0)
    const { user } = useAuth()
    const canAdd = user.permissions.includes('add_service')
    const canChange = user.permissions.includes('change_service')
    
    // DataCRUD callbacks
    const onSaveData = (result) => {
        if (result?.error) {
            console.log(`Error: ${result}`)
        } else {
            console.log(`Data saved: ${result}`)
        }
    }

    const onCreateData = (result) => {
        // update object data?
        if (result?.error) {
            console.log(`Error: ${result}`)
        } else {
            console.log(`Data created: ${result}`)
        }
    }

    return (
        <UiShell>
            <Stack gap={8}>
                <Section>
                    <Heading className="primary">Bens e Serviços</Heading>
                    <p>A lista de bens e serviços define e delimita o que entidades vinculadas podem requerer através dos pedidos de cotação.</p>
                </Section>
                <Section>
                    <Section  style={{ marginBottom: '2.5rem' }}>
                        <Tabs selectedIndex={type} onChange={(e) => setType(e.selectedIndex)}>
                            <TabList aria-label="Filtros">
                                <Tab key={0}>{"Serviços"}</Tab>
                                <Tab key={1}>{"Bens"}</Tab>
                            </TabList>
                        </Tabs>
                    </Section>
                    {type === 0 &&
                        <DataCRUD 
                            title={'Serviços'} 
                            desc={'Todos os cadastros'} 

                            {...servicesDataCRUD}

                            canAdd={canAdd}
                            canChange={canChange}
                            onSave={onSaveData}
                            onCreate={onCreateData}
                        /> 
                    }
                    {type === 1 &&
                        <DataCRUD 
                            title={'Bens'} 
                            desc={'Todos os cadastros'} 

                            {...goodsDataCRUD}

                            canAdd={canAdd}
                            canChange={canChange}
                            onSave={onSaveData}
                            onCreate={onCreateData}
                        /> 
                    }
                </Section>
            </Stack>
        </UiShell>
    )
}

export default Services