
// api imports
import { getProductsApi, setProductsApi, getProductsCategoryCompraSERVApi } from '../utils/api/products'

// DataManager form setup
const form = [
    [
        {
            label: 'Nome',
            helper: '',
            type: 'text',
            key: 'name'
        },
        {
            label: 'Tipo',
            helper: '',
            type: 'select',
            fetchApi: getProductsCategoryCompraSERVApi,
            optionText: 'name',
            key: 'product_type'
        },
        {
            label: 'CódigoBR/SIGTAP/CATMAT/Outro',
            helper: '',
            type: 'text',
            key: 'code_br'
        }
    ],
    [
        {
            label: 'Apresentação',
            helper: '',
            type: 'text',
            key: 'presentation'
        },
        {
            label: 'Prazo de Validade ou Garantia',
            helper: '',
            type: 'text',
            key: 'expire_time'
        },
        {}
    ],
    [
        {
            label: 'Descrição',
            helper: 'Limite de 500 caracteres',
            type: 'longText',
            key: 'description'
        }
    ],
    [
        {
            label: 'Observações',
            helper: 'Limite de 500 caracteres',
            type: 'longText',
            key: 'observation'
        }
    ],
    [
        {
            label: 'Data de Cadastro',
            helper: '',
            type: 'text',
            key: 'created_at',
            disabled: true
        },
        {
            label: 'Última alteração em',
            helper: '',
            type: 'text',
            key: 'last_updated',
            disabled: true
        },
        {}
    ],
    [
        {
            label: 'Anexos',
            helper: '',
            type: 'multimedia',
            key: 'attachments',
            medias: [
                {
                    label: 'Documentos',
                    helper: 'Tamanho máximo de arquivo é 2Mb. Formatos suportados: Doc, PDF.',
                    key: 'doc'
                },
                {
                    label: 'Fotos e Imagens',
                    helper: 'Tamanho máximo de arquivo é 2Mb. Formatos suportados: JPG e PNG.',
                    key: 'image'
                },
                {
                    label: 'Videos',
                    helper: 'Suporte a links disponibilizados publicamente. Serviços suportados: Youtube, Vimeo, Dailymotion',
                    key: 'url'                    
                }
            ]
        }
    ]
]

// DataGrid headers and data table formating setup
const dataGridHeader = [
    {
        header: 'Nome',
        key: 'name'
    },
    {
        header: 'Tipo',
        key: 'product_type_code'
    },
    {
        header: 'Apresentação',
        key: 'presentation'
    },
    {
        header: 'Código',
        key: 'code_br'
    },
    {
        header: 'Descrição',
        key: 'description'
    },
    {
        header: 'Anexos',
        key: 'attachments_count'
    }
]

const dataGridSearchBy = dataGridHeader.map(item => item.key.endsWith('_code') ? item.key.slice(0, -5) : item.key)

// the main DataCRUD setup
export const goodsDataCRUD = {
    headers: dataGridHeader, 
    searchBy: dataGridSearchBy,
    form: form,
    formTitleKey: 'name',
    getApi: getProductsApi,
    setApi: setProductsApi
}