import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios'
import { Modal } from '@carbon/react'
import { decodeToken } from '../utils/jwt-decode'

// api imports
import { getProfileApi } from '../utils/api/accounts'

// Define the user context
const AuthContext = createContext();

// Auth context provider component
export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null)
    const [isUserChecked, setIsUserChecked] = useState(false)
    const [ logoutNoticeModal, setLogoutNoticeModal ] = useState(false)

    // Only when user refresh the page or visit later
    // try to get user profile using token if its not expired
    useEffect(() => {
        // first check the localStorage token existence
        const token = localStorage.getItem('access_token')
        if (!token) {
            setIsUserChecked(true)
            return
        }
        
        // decode token for user_id
        const decodedToken = decodeToken(token);
        
        // do we have user_id?
        if (!decodedToken?.hasOwnProperty('user_id')) {
            setIsUserChecked(true)
            return
        }

        const userId = decodedToken.user_id

        // set our token as default
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        // query account profile using user_id
        async function checkUser() {
            if (user === null) {
                const { profile } = await getProfileApi(userId)
                
                if (profile) {
                    setUser(profile)
                    setIsUserChecked(true)
                }
            }
        }
        checkUser()

    }, [])

    useEffect(() => {
        // do we have access time restrictions?
        // program a modal to showup 1 hour before system goes on access restriction
        // also user.access_time === "23:59:00" means free access with no time restriction
        if (user?.access_time && user?.access_time !== "23:59:00") {
            const currentTime = new Date()
            const accessDateTime = new Date(currentTime.toDateString() + " " + user.access_time)
            accessDateTime.setHours(accessDateTime.getHours() - 1)
            const timeoutDuration = accessDateTime - currentTime
            
            setTimeout(function() {
                setLogoutNoticeModal(true)
            }, timeoutDuration)
        }
    }, [user])

    if (!isUserChecked && user === null) return <></>

    return (
        <AuthContext.Provider value={{ user, setUser }}>
            {logoutNoticeModal === true &&
                <Modal 
                    open={logoutNoticeModal}
                    modalHeading="O sistema entrará em restrição de acesso dentro de 1 hora. Não será possível realizar nenhuma operação após este período."
                    modalLabel="Alerta de Restrição de Horário" 
                    primaryButtonText="Fechar" 
                    onRequestSubmit={() => setLogoutNoticeModal(false)} 
                />
            }
            {children}
        </AuthContext.Provider>
    );
};

// Custom hook to access the user context
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};