import { 
    Section, 
} from '@carbon/react';
import { DataCRUD } from './DataCRUD'

// api imports
import { getUserLogsApi } from '../utils/api/accounts'

const form = [
    [
        {
            label: 'IP',
            type: 'text',
            key: 'remote_addr'
        },
        {
            label: 'Usuário',
            type: 'text',
            key: 'user_username'
        },
        {
            label: 'Operação',
            type: 'text',
            key: 'operation_name'
        },
    ],
    [
        {
            label: 'Registro',
            type: 'text',
            key: 'registry_name'
        },
        {
            label: 'Alteracoes',
            type: 'text',
            key: 'change_num'
        },
        {
            label: 'Data e Hora',
            type: 'text',
            key: 'datetime'
        }
    ],
    [
        {
            label: 'Alterações',
            type: 'header'
        }
    ],
    [
        {
            label: 'Alteracoes',
            helper: '',
            type: 'showCase',
            key: 'changes'
        }
    ],
    [
        {
            label: 'Title',
            helper: '',
            type: 'hidden',
            key: 'object_repr',
            disabled: true
        }
    ]
]

// DataGrid headers and data table formating setup
const logsDataGridHeader = [
    {
        header: 'IP',
        key: 'remote_addr'
    },
    {
        header: 'Usuário',
        key: 'user_username'
    },
    {
        header: 'Operação',
        key: 'operation_name'
    },
    {
        header: 'Registro',
        key: 'registry_name'
    },
    {
        header: 'Alteracoes',
        key: 'change_num'
    },
    {
        header: 'Data e Hora',
        key: 'datetime'
    }
]

const logsDataGridSearchBy = ['remote_addr', 'changes', 'registry_name', 'datetime']

const SYSTEM_REGISTRIES = {
    'accounts.user': 'Perfil de Usuário',
    'orders.order': 'Solicitação de Compra',
    'suppliers.supplier': 'Fornecedores',
    'public_entities.publicentity': 'Entidade Pública',
    'products.product': 'Produto',
    'balance.balance': 'Empenho Orçamentário'
}

const SYSTEM_OPERATIONS = {
    'create': 'Criação',
    'update': 'Atualização'
}

const SYSTEM_REGISTRIES_MAPPING = {
    'accounts.user': {
        'first_name': 'Nome', 
        'last_name': 'Sobrenome',
        'group': 'Nivel de Acesso', 
        'email': 'Email',
        'is_active': 'Status',
        'password': 'Senha'
    },
    'orders.order': {
        'number': 'Código do Pedido',
        'requester_name': 'Solicitante',
        'quote_deadline': 'Prazo para envio de cotações',
        'shipping_deadline': 'Prazo de entrega',
        'order_type': 'Critério de Seleção',
        'urgent': 'Urgência',
        'quote_num': 'Número Mínimo de Cotações',
        'status_code': 'Status',
        'responsible': 'Responsável',
        'creator': 'Cadastrado por',
        'created_at': 'Data de Cadastro',
        'last_updated': 'Última alteração em',
        'justification': 'Justificativa',
        'observation': 'Observações',
        'cancel_desc': 'Motivo do Cancelamento',
        'total': 'Processamento',
        'status': 'Estado da Solicitação',
        'order_items': 'Order Items',
    },
    'suppliers.supplier': {
        'cnpj': 'CNPJ',
        'name': 'Nome Fantasia',
        'social_name': 'Razão Social',
        'cep': 'CEP',
        'endereco': 'Endereço',
        'numero': 'Número',
        'complemento': 'Complemento',
        'uf': 'UF',
        'cidade': 'Cidade',
        'contract_number': 'Contrato Número',
        'platform_admin_rate': 'Taxa Administrativa (%)',
        'contract_start_date': 'Firmado em',
        'contract_status': 'Status do Contrato',
        'phone': 'Telefone',
        'email': 'E-mail'
    },
    'public_entities.publicentity': {
        'cnpj': 'CNPJ',
        'name': 'Nome',
        'parent': 'Subordinada a(o)',
        'cep': 'CEP',
        'endereco': 'Endereço',
        'numero': 'Número',
        'complemento': 'Complemento',
        'uf': 'UF',
        'cidade': 'Cidade',
        'contract_number': 'Contrato Número',
        'contract_income': 'Empenho Orçamentário',
        'contract_outcome': 'Despesa Autorizada',
        'manager_account': 'Gestor',
        'contract_start_date': 'Firmado em',
        'contract_end_date': 'Contrato válido até:',
        'platform_admin_rate': 'Taxa Administrativa (%)',
        'contract_status_code': 'Status do Contrato',
        'phone': 'Telefone',
        'email': 'E-mail'
    },
    'products.product': {
        'name': 'Nome do Produto',
        'substance': 'Substância',
        'code_br': 'CódigoBR',
        'registry': 'Registro',
        'product_type': 'Tipo',
        'therapeutic_class': 'Classe Terapêutica CMED',
        'origin_list': 'Lista de Origem',
        'presentation': 'Apresentação do Produto',
        'concentration': 'Concentração',
        'therapeutic_form': 'Forma Terapéutica',
        'price': 'Preço Fábrica (PF) Sem Impostos',
        'description': 'Descrição',
        'observation': 'Observações',
        'created_at': 'Data de Cadastro',
        'last_updated': 'Última alteração em'
    },
    'balance.balance': {
        'number': 'Número do Empenho',
        'issue_date': 'Data de Emissão',
        'balance_type': 'Espécie',
        'total': 'Valor Destinado',
        'creator': 'Cadastrado por',
        'total': 'Notas',
        'object_desc': 'Objeto',
        'destination_desc': 'Destinação',
        'cancel_desc': 'Motivo do Cancelamento',
        'total': 'Processamento',
        'created_at': 'Data de Cadastro',
        'last_updated': 'Última alteração em',
        'status': 'Status for Actions',
    },
}

export const UserLogs = ({ userData }) => {
    
    // DataCRUD callbacks
    const onLoadLogs = (data) => {
        // adding pt-br translations based on symbols table 
        data.forEach(e => {
            e.registry_name = SYSTEM_REGISTRIES?.[e.registry]
            e.operation_name = SYSTEM_OPERATIONS?.[e.action_text]
            //e.datetime = new Date(e.timestamp).toLocaleString('pt-BR')
            e.datetime = e.timestamp

            try {
                e.change_num = 0
                e.changes = JSON.parse(e.changes)
                // walk thru all e.changes objects and map their keys based on this mapping structure: SYSTEM_REGISTRIES_MAPPING?.[e.registry]
                // use the structure value of each key to replace its own key
                if (typeof e.changes === 'object' && e.changes !== null) {
                    for (const key in e.changes) {
                        if (SYSTEM_REGISTRIES_MAPPING?.[e.registry][key]) {
                            const translatedKey = SYSTEM_REGISTRIES_MAPPING[e.registry][key];
                            e.changes[translatedKey] = e.changes[key];
                            delete e.changes[key];
                        }
                    }
                    // create changes counter
                    e.change_num = Object.keys(e.changes).length
                }
                
            } catch (error) {
                console.error('Error parsing changes field:', error)
            }
        })
        return data
    }

    const customTitle = (data) => `${data?.registry_name}: ${data?.object_repr}`

    return (
        <Section>
            <DataCRUD 
                form={form}
                /* formTitleKey={'object_repr'} */
                formTitleFunc={customTitle}
                title={'Operações Realizadas'} 
                desc={userData?.name} 
                headers={logsDataGridHeader} 
                searchBy={logsDataGridSearchBy}
                getApi={getUserLogsApi}
                getApiParam={{ userId: userData?.id }}
                getApiProcessData={onLoadLogs}
                canAdd={false}
                canChange={false}
            /> 
        </Section>
    )
}

export default UserLogs