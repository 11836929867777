import axios from "axios";

export const getTicketsApi = async (data = {}) => {
  let response
  if (data?.id)
    response = await axios.get(`/tickets/${data.id}/`, {withCredentials: true})
  else
    response = await axios.get('/tickets/', { ...data }, {withCredentials: true})
  return response.status === 200 ? response.data : null
}

export const createTicketApi = async (data) => {
  return await axios.post('/tickets/', { ...data }, {withCredentials: true})
}

export const assignTicketApi = async (data) => {
  return await axios.post(`/tickets/assign/${data?.id}/`, {withCredentials: true})
}

export const closeTicketApi = async (data) => {
  return await axios.post(`/tickets/close/${data?.id}/`, { ...data }, {withCredentials: true})
}

export const sendTicketMessageApi = async (data) => {
  if (data?.id !== undefined)
    return await axios.post(`/tickets/add_message/${data?.id}/`, { ...data }, {withCredentials: true})
  else
    return await axios.post('/tickets/', { ...data }, {withCredentials: true})
}