// excel support
import * as XLSX from 'xlsx'
import { downloadExport } from './downloadData'
import { parseHtmlFormsToJson } from './htmlToJson'

export const xlsExporter = (htmlData) => {
    const exporterData = parseHtmlFormsToJson(htmlData, true)

    const wb = XLSX.utils.book_new()
    //const ws = XLSX.utils.aoa_to_sheet(exporterData)
    const ws = XLSX.utils.json_to_sheet(exporterData)
    XLSX.utils.book_append_sheet(wb, ws, "CompreMED")
    //XLSX.writeFile(wb, "exported_data.xlsx")

    const xlsData = XLSX.write(wb, { type: 'array', bookType: 'xlsx' }); // Set type to 'array'
    const xlsBlob = new Blob([xlsData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    downloadExport(xlsBlob, 'exported_data.xlsx')
}
