import axios from "axios";

// GET: keep simple and return only data or null
export const getEntitiesApi = async (data = {}) => {
  const response = await axios.get('/entities/', { ...data }, {withCredentials: true})
  return response.status === 200 ? response.data : null
}

export const getLinkedEntitiesApi = async (data = {}) => {
  const response = await axios.get(`/entities/linked/${data?.entityId ?? ''}`, { ...data }, {withCredentials: true})
  return response.status === 200 ? response.data : null
}

export const getParentAndOwnEntitiesApi = async (entityId=null) => {
  if (entityId === null) return
  const response = await axios.get(`/entities/parent/${entityId}`, {withCredentials: true})
  return response.status === 200 ? response.data : null
}

export const getEntitiesHierarchyApi = async (entityId=null) => {
  const response = await axios.get(`/entities/hierarchy/${entityId === null ? '' : entityId}`, {withCredentials: true})
  return response.status === 200 ? response.data : null
}

// POST to create if no id property on data
// PATCH to update if id property on data
// return whole request object for custom handling
export const setEntitiesApi = async (data) => {
  if (data.hasOwnProperty('id'))
    return await axios.patch(`/entities/${data.id}/`, { ...data }, {withCredentials: true})
  else
    return await axios.post('/entities/', { ...data }, {withCredentials: true})
}

export const getPublicEntitiesStats = async () => {
  const response = await axios.get('/entities/stats/', { withCredentials: true })
  return response.status === 200 ? response.data : null
}