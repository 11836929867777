import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
    Content, 
    Header, 
    HeaderMenuButton, 
    HeaderName, 
    HeaderGlobalBar, 
    SkipToContent, 
    SideNav, 
    MenuButton,
    MenuItem,
    Modal
} from '@carbon/react'
//import { Notification } from '@carbon/icons-react';
import { useAuth } from '../contexts/AuthContext';
import Logo from '../components/Logo'
import NavigationMenu from '../components/NavigationMenu'
// api imports
import { logoutApi } from '../utils/api/auth'

export const scrollToTop = () => {
    // scroll to content smooothly
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    })
}

export const UiShell = ({ children }) => {
    const [ isSideNavExpanded, setIsSideNavExpanded ] = useState(false)
    const [ platformVersion, setPlatformVersion ] = useState(false)
    const navigate = useNavigate()
    const { user, setUser } = useAuth()
    const version = process.env.REACT_APP_VERSION
    const appTitle = window.APP_TITLE
    const changelog = process.env.REACT_APP_CHANGELOG_MESSAGE?.replace(/\s{2,}/g, '<br/>')

    const logoutUser = () => {
        const resp = logoutApi()
        setUser(null)
        navigate('/login')
    }

    const onClickSideNavExpand = () => {
        setIsSideNavExpanded(!isSideNavExpanded)
    }

    useEffect(() => {
        // scroll screen to the top
        scrollToTop()
    },[])

    return (
            <>
                <Header aria-label={appTitle}>
                    
                    {platformVersion &&
                        <Modal 
                            open={platformVersion}
                            modalHeading={appTitle}
                            primaryButtonText="Ok" 
                            onRequestSubmit={() => setPlatformVersion(false) } 
                            onRequestClose={() => setPlatformVersion(false) } 
                        >
                            <p>Versão {version}</p>
                            <p>Frontend: React 11.32.0 (HTML5, CSS3, Javascript)</p>
                            <p>Changelog:</p>
                            <p dangerouslySetInnerHTML={{ __html: changelog }} />
                        </Modal>
                    }

                    <SkipToContent />

                    <HeaderMenuButton aria-label={isSideNavExpanded ? 'Fechar menu' : 'Abrir menu'} onClick={onClickSideNavExpand} isActive={isSideNavExpanded} aria-expanded={isSideNavExpanded} />
                    
                    <HeaderName href="#" prefix="">
                        <Logo small />
                    </HeaderName>

                    <HeaderGlobalBar>
                        {/* <HeaderGlobalAction aria-label="Notifications" onClick={() => {}}>
                            <Notification size={20} />
                        </HeaderGlobalAction> */}
                        <MenuButton kind="ghost" label={user.name}>
                            <MenuItem onClick={() => navigate("/profile")} label="Perfil" />
                            <MenuItem onClick={() => setPlatformVersion(true)} label="Sobre a Plataforma" />
                            <MenuItem onClick={() => logoutUser()} label="Sair" />
                        </MenuButton>
                    </HeaderGlobalBar>

                    <SideNav aria-label="Side navigation" expanded={isSideNavExpanded} onSideNavBlur={onClickSideNavExpand}>
                        <NavigationMenu logoutUser={logoutUser} user={user} />
                    </SideNav>

                </Header>

                <Content id="main-content">
                    {children}
                </Content>
            </>
    )
}
