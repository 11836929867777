import { downloadExport } from './downloadData'
import { parseHtmlFormsToJson } from './htmlToJson'

function parseToTXT(data) {
    let txt = "";

    data.forEach((item, index) => {
        //txt += `item[${index}]\r\n`;
        Object.entries(item).forEach(([key, value]) => {
            txt += `${key}: ${value}\r\n`;
        });
        txt += '========================================\r\n';
    });

    return txt;
}

export const txtExport = (htmlData) => {
    const exporterData = parseHtmlFormsToJson(htmlData, true)

    const textContent = parseToTXT(exporterData)

    const BOM = new Uint8Array([0xEF,0xBB,0xBF])
    const textBlob = new Blob([ BOM, textContent ])

    //const textBlob = new Blob([textContent], { type: 'text/plain; charset=utf-8;' })
    downloadExport(textBlob, 'exported_data.txt')
}
