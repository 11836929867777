import axios from "axios";

// GET: keep simple and return only data or null
export const getSuppliersApi = async (data = {}) => {
  const response = await axios.get('/suppliers/', { ...data }, {withCredentials: true})
  return response.status === 200 ? response.data : null
}

// POST to create if no id property on data
// PATCH to update if id property on data
// return whole request object for custom handling
export const setSuppliersApi = async (data) => {
  if (data.hasOwnProperty('id'))
    return await axios.patch(`/suppliers/${data.id}/`, { ...data }, { headers: {
      'Content-Type': 'multipart/form-data',
    }}, {withCredentials: true})
  else
    return await axios.post('/suppliers/', { ...data }, { headers: {
      'Content-Type': 'multipart/form-data',
    }}, {withCredentials: true})
}
