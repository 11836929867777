import axios from "axios";

export const USER_MANUAL_URL = 'http://localhost:3001/'
const USER_MANUAL_ENDPOINT = 'http://localhost:4001/graphql'

export const getUserManualDataApi = async (collectionName) => {
  const query = JSON.stringify({
    query: `
      {
        ${collectionName}Connection {
          edges {
            node {
              id
              title
              description
              body
            }
          }
        }
      }
    `,
  })

  const response = await axios.post(USER_MANUAL_ENDPOINT, query)
  return response?.data?.data?.[`${collectionName}Connection`]?.edges
  //return response.status === 200 ? response.data.data.docConnection.edges : null
}
